export const printStyles = `
@page {
    size: auto !important;
    /* margin-top: 0.75in !important;
    margin-bottom: 0.75in !important;
    margin-left: 0.75in !important;
    margin-right: 0.75in !important; */
    margin-top: 10mm !important;
    margin-bottom: 10mm !important;
    margin-left: 1mm !important;
    margin-right: 1mm !important;

}
@media print {
    .print-col-1 {
        width: 5% !important
    }

    .print-col-2 {
        width: 10% !important
    }

    .print-col-3 {
        width: 20% !important
    }

    .print-col-4 {
        width: 25% !important
    }

    .print-col-5 {
        width: 30% !important
    }

    .print-only {
        display: block !important;
    }
    .print-content-only {
        padding: 0 !important;
        background: none !important;
    }

    .print-content-only .docs-wrapper,
    .print-content-only .docs-page,
    .print-content-only .docs-page-title .docs-content,
    .print-content-only .container,
    .print-content-only .container-fluid,
    .print-content-only .container-sm,
    .print-content-only .container-md,
    .print-content-only .container-lg,
    .print-content-only .container-xl,
    .print-content-only .container-xxl {
        background: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .print-content-only .docs-aside,
    .print-content-only docs- .scrolltop,
    .print-content-only .docs-header,
    .print-content-only .drawer,
    .print-content-only .btn {
        display: none !important;
    }
    
html,
body {
    width: 210mm !important;
    height: 100% !important;
    /* height: 282mm !important; */
    font-size: 11px !important;
    background: #FFF !important;
    overflow: hidden !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
}

body {
    padding: 0 !important;

}

.header-fixed.toolbar-fixed .wrapper {
    padding-top: 0;
    max-width: 100% !important;
    width: 100% !important;
}

.kt_content_container,
.table-responsive {
    max-width: 100% !important;
    width: 100% !important;
}

table {
    width: 100% !important;
    max-width: 100% !important;
    page-break-inside: auto !important;
    position: relative;
}



body {
    /* overflow: hidden !important; */
    overflow: visible !important;
    height: fit-content !important;
}

#root *:not(.page, .page *) {
    display: none !important;
}

#kt_content {
    padding: 0;
}

.modal {
    overflow: visible;
    position: relative !important;
    top: 0 !important;
    height: fit-content;
    width: 100%;
}

table thead {
    display: table-header-group !important;
}

table tfoot {
    display: table-footer-group !important;
}

div {
    page-break-inside: auto;
}

table th,
table td {
    page-break-inside: avoid !important;
    border: 1px solid lightgray !important;
}


table tr,
table tbody {
    page-break-inside: auto !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
}

.break-always {
    page-break-before: always !important;
    float: none;
    position: relative !important;
    display: block !important;
    page-break-inside: avoid;
    /* width: 100% !important; */
}

.modal-dialog {
    align-items: flex-start !important;
    width: 100%;
    margin: 0;
}

.table tfoot tr:last-child,
.table tbody tr:last-child {
    border-bottom: 1px solid lightgray !important;
}

.table.gs-0 th:first-child,
.table.gs-0 td:first-child {
    padding-right: 0.75rem !important
}

.print-content-only {
    padding: 0 !important;
    background: none !important;
}

thead {
    display: table-header-group !important;
}

tfoot {
    display: table-row-group !important;
}

tr {
    page-break-inside: avoid !important;
}


.print-content-only .wrapper,
.print-content-only .page,
.print-content-only .page-title .content,
.print-content-only .container,
.print-content-only .container-xxl,
.print-content-only .container-fluid,
.print-content-only .container-sm,
.print-content-only .container-md,
.print-content-only .container-lg,
.print-content-only .container-xl {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
}

.print-content-only .aside,
.print-content-only .sidebar,
.print-content-only .scrolltop,
.print-content-only .header,
.print-content-only .footer,
.print-content-only .toolbar,
.print-content-only .drawer,
.print-content-only .btn,
.no-print {
    display: none !important;
}
* {
box-shadow: none !important;
color: black !important;
float: none !important;
}

.modal-dialog {
max-width: 100% !important;
}

::-webkit-scrollbar {
display: none !important;
}

.element::-webkit-scrollbar {
width: 0 !important;
-ms-overflow-style: none !important;
overflow: -moz-scrollbars-none !important;
}

table {
table-layout: fixed !important
}
}
`