import axios from 'axios'
import { AuthModel } from '../models/AuthModel'
import { UserModel } from '../models/UserModel'
import { api } from '../../../../constants'


export const GET_USER_BY_ACCESSTOKEN_URL = `${api.auth.getUserByToken}`
export const LOGIN_URL = `${api.auth.login}`
export const REGISTER_URL = `${api.auth.register}`
export const REQUEST_PASSWORD_URL = `${api.auth.login}`
export const CONFIRM_OTP_URL = `${api.auth.confirmOTP}`
export const RESEND_OTP_URL = `${api.auth.resendOTP}`

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post(LOGIN_URL, { 'username': email, 'password': password })
}

// Server should return AuthModel
export function confirmOTP(email: string, otp: string) {
    return axios.post(CONFIRM_OTP_URL, { 'username': email, 'otp': otp })
}

// Server should return AuthModel
export function resendOTP(email: string) {
    return axios.post(RESEND_OTP_URL, { 'username': email })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email })
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
