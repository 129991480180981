import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'


const CustomTable: FC<any> = ({ isLoading, data, type, subtype }) => {
    const [tableData, setTableData] = useState<any[]>(data || [])
    const [tableColumns, setTableColumns] = useState<any[]>([])
    const [loading, setLoading] = useState(isLoading)
    const intl = useIntl()
    useEffect(() => {
        if (Array.isArray(data)) {
            setTableData([...data])
        }
        else {
            let combindData: any[] = []
            data?.contracts?.map((contract: any, index: number) => {
                let obj = {
                    id: contract.id,
                    dropOffDate: contract.dropOffDate,
                    remainingAmount: contract.remainingAmount || 0,
                    totalPaid: contract.totalAmount - (contract.remainingAmount || 0),
                    renterName: contract.renterName,
                    renterPhone: contract.renterPhone,
                    manufacturingYear: data.cars.filter((car: any) => car.id == contract.carId)[0]?.manufacturingYear,
                    carNumber: data.cars.filter((car: any) => car.id == contract.carId)[0]?.plateNumber,
                    carModelName: data.cars.filter((car: any) => car.id == contract.carId)[0]?.modelName,
                    carColor: data.cars.filter((car: any) => car.id == contract.carId)[0]?.color,

                    userName: contract.userName
                }
                combindData.push(obj)
            })
            setTableData([...combindData])
        }
        setTableColumns(getTableColumn(type, subtype))
    }, [data])
    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    const getTableColumn = (type: number, subtype: number) => {
        switch (type) {
            case 2:
                switch (subtype) {
                    case 1:
                        return [
                            { th: 'CAR.NUMBER', td: 'plateNumber' },
                            { th: 'CARMODEL.NAME', td: 'modelName' },
                            { th: 'GENERAL.COLOR', td: 'color', },
                            { th: 'CAR.ADD.ENGINECAPACITY', td: 'engineCapacity' },
                            { th: 'CAR.ADD.MANUFACTURINGYEAR', td: 'manufacturingYear' },
                            { th: 'CAR.ADD.EXTRAS', td: 'extras' }
                        ]
                    case 3:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'id', printClass: 'print-col-1' },
                            { th: 'CONTRACT.DROPOFFDATE', td: 'dropOffDate', type: 'datetime', printClass: 'print-col-2' },
                            { th: 'CONTRACT.REMAININGAMOUNT', td: 'remainingAmount', printClass: 'print-col-1' },
                            { th: 'CONTRACT.TOTALPAID', td: 'totalPaid', printClass: 'print-col-1' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName', printClass: 'print-col-3' },
                            { th: 'CONTRACT.RENTERPHONE', td: 'renterPhone', printClass: 'print-col-2' },
                            { th: 'CAR.NUMBER', td: 'carNumber', printClass: 'print-col-1' },
                            { th: 'CAR.ADD.MODEL', td: 'manufacturingYear', printClass: 'print-col-1' },
                            { th: 'CAR.TYPE', td: 'carModelName', printClass: 'print-col-2' },
                            { th: 'GENERAL.COLOR', td: 'carColor', printClass: 'print-col-1' },
                            { th: 'GENERAL.EMPLOYEE', td: 'userName', printClass: 'print-col-2' },
                        ]
                    case 4:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'id', printClass: 'print-col-1' },
                            { th: 'CONTRACT.DROPOFFDATE', td: 'dropOffDate', type: 'datetime', printClass: 'print-col-2' },
                            { th: 'CONTRACT.REMAININGAMOUNT', td: 'remainingAmount', printClass: 'print-col-1' },
                            { th: 'CONTRACT.TOTALPAID', td: 'totalPaid', printClass: 'print-col-1' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName', printClass: 'print-col-3' },
                            { th: 'CONTRACT.RENTERPHONE', td: 'renterPhone', printClass: 'print-col-2' },
                            { th: 'CAR.NUMBER', td: 'carNumber', printClass: 'print-col-1' },
                            { th: 'CAR.ADD.MODEL', td: 'manufacturingYear', printClass: 'print-col-1' },
                            { th: 'CAR.TYPE', td: 'carModelName', printClass: 'print-col-2' },
                            { th: 'GENERAL.COLOR', td: 'carColor', printClass: 'print-col-1' },
                            { th: 'GENERAL.EMPLOYEE', td: 'userName', printClass: 'print-col-2' },
                        ]

                    default:
                        return [
                            { th: 'CAR.NUMBER', td: 'plateNumber' },
                            { th: 'CARMODEL.NAME', td: 'modelName' },
                            { th: 'GENERAL.COLOR', td: 'color', },
                            { th: 'CAR.ADD.ENGINECAPACITY', td: 'engineCapacity' },
                            { th: 'CAR.ADD.MANUFACTURINGYEAR', td: 'manufacturingYear' },
                            { th: 'CAR.ADD.EXTRAS', td: 'extras' }
                        ]
                }
            case 3:
                switch (subtype) {
                    case 1:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'id' },
                            { th: 'CONTRACT.DROPOFFDATE', td: 'dropOffDate', type: 'datetime' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName' },
                            { th: 'CONTRACT.RENTERPHONE', td: 'renterPhone' },
                            { th: 'CAR.NUMBER', td: 'carNumber' },
                            { th: 'CAR.ADD.MODEL', td: 'carModelName' },
                            { th: 'GENERAL.COLOR', td: 'carColor' }
                        ]
                    case 2:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'id' },
                            { th: 'CONTRACT.DROPOFFDATE', td: 'dropOffDate', type: 'datetime' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName' },
                            { th: 'CONTRACT.RENTERPHONE', td: 'renterPhone' },
                            { th: 'CAR.NUMBER', td: 'carNumber' },
                            { th: 'CAR.ADD.MODEL', td: 'carModelName' },
                            { th: 'GENERAL.COLOR', td: 'carColor' }
                        ]
                    case 3:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'contractId' },
                            { th: 'GENERAL.EMPLOYEE', td: 'userName' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName' },
                            { th: 'CAR.NUMBER', td: 'carId' },
                            { th: 'CAR.ADD.MODEL', td: 'carModel' },
                            { th: 'CONTRACT.DURATION', td: 'duration' },
                            { th: 'CONTRACT.PRICE', td: 'price' },
                            { th: 'CONTRACT.TOTALAMOUNT', td: 'amount' },
                            { th: 'CONTRACT.TOTALPAID', td: 'totalPaid' },
                            { th: 'CONTRACT.REMAININGAMOUNT', td: 'remaining', printClass: 'text-danger rounded text-bold', printStyle: { background: 'yellow' } },
                        ]
                    case 4:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'contractId' },
                            { th: 'GENERAL.EMPLOYEE', td: 'userName' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName' },
                            { th: 'CAR.NUMBER', td: 'carId' },
                            { th: 'CAR.ADD.MODEL', td: 'carModel' },
                            { th: 'CONTRACT.DURATION', td: 'duration' },
                            { th: 'CONTRACT.PRICE', td: 'price' },
                            { th: 'CONTRACT.TOTALAMOUNT', td: 'amount' },
                            { th: 'CONTRACT.TOTALPAID', td: 'totalPaid' },
                            { th: 'CONTRACT.REMAININGAMOUNT', td: 'remaining', printClass: 'text-danger rounded text-bold', printStyle: { background: 'yellow' } },

                        ]
                    case 6:
                        return [
                            { th: 'CAR.NUMBER', td: 'plateNumber' },
                            { th: 'CARMODEL.NAME', td: 'modelName' },
                            { th: 'GENERAL.COLOR', td: 'color', },
                            { th: 'CAR.ADD.ENGINECAPACITY', td: 'engineCapacity' },
                            { th: 'CAR.ADD.MANUFACTURINGYEAR', td: 'manufacturingYear' },
                            { th: 'CAR.ADD.LICENSENDDATE', td: 'licenseEndDate', type: 'datetime' },

                        ]
                    default:
                        return [
                            { th: 'CONTRACT.NUMBER', td: 'id' },
                            { th: 'CONTRACT.DROPOFFDATE', td: 'dropOffDate', type: 'datetime' },
                            { th: 'CONTRACT.RENTERNAME', td: 'renterName' },
                            { th: 'CONTRACT.RENTERPHONE', td: 'renterPhone' },
                            { th: 'CAR.NUMBER', td: 'carNumber' },
                            { th: 'CAR.ADD.MODEL', td: 'carModelName' },
                            { th: 'GENERAL.COLOR', td: 'carColor' }
                        ]
                }
            default:
                return [
                    { th: 'CAR.NUMBER', td: 'plateNumber' },
                    { th: 'CARMODEL.NAME', td: 'modelName' },
                    { th: 'GENERAL.COLOR', td: 'color', },
                    { th: 'CAR.ADD.ENGINECAPACITY', td: 'engineCapacity' },
                    { th: 'CAR.ADD.MANUFACTURINGYEAR', td: 'manufacturingYear' },
                    { th: 'CAR.ADD.EXTRAS', td: 'extras' }
                ]
        }
    }


    return (
        <div className='table-responsive ' >

            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 '>
                {/* begin::Table head */}
                <thead >
                    <tr className='fw-bolder text-muted'>
                        <th className={`text-center print-col-1`}>{intl.formatMessage({ id: 'GENERAL.NUMBER' })}</th>
                        {tableColumns?.map((column: any, index: number) => (
                            <th key={index} className={`text-center ${column.printClass || ''} text-wrap`}>{intl.formatMessage({ id: column.th })}</th>
                        ))}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                {!loading ?
                    <tbody>
                        {
                            tableData?.length ?
                                tableData?.map((item: any, index: number) => (
                                    <React.Fragment key={`m-tr${index}`}>
                                        <tr id={`m-tr${index}`} >
                                            <td className={`text-center print-col-1 `}>
                                                <span >
                                                    {index + 1}
                                                </span>
                                            </td>
                                            {tableColumns?.map((column: any, index: number) => (
                                                <td key={index} className={`text-center ${column.printClass || ''}  `} style={{ ...column.printStyle }}>
                                                    <span {...column.tdProps} className='text-center'>
                                                        {column.type === 'date' ? intl.formatDate(item[column.td]) : column.type === 'time' ? intl.formatTime(item[column.td]) :
                                                            column.type === 'datetime' ? `${intl.formatDate(item[column.td])} ${intl.formatTime(item[column.td])}` :
                                                                item[column.td] || item[column.td] === 0 ? item[column.td] : intl.formatMessage({ id: 'GENERAL.NODATA1' })}
                                                    </span>
                                                </td>
                                            ))}
                                        </tr>
                                        {/* {!!(index !== 0 && ((index % 15) === 0)) &&
                                            <tr id={`s-tr${index}`} className="only-print text-center w-100 no-border landscape-custom-spacer">
                                                <td className={`text-center print-col-1 no-border`} style={{ height: '50px' }}>here</td>
                                            </tr>
                                        }
                                        {!!(index !== 0 && ((index % 18) === 0)) &&
                                            <tr id={`s-tr${index}`} className="only-print text-center w-100 no-border portrait-custom-spacer">
                                                <td className={`text-center print-col-1 no-border`} style={{ height: '50px' }}>here</td>
                                            </tr>
                                        } */}
                                    </React.Fragment>
                                ))
                                :
                                <tr style={{ width: '100%', textAlign: 'center', padding: '20px 0' }} >
                                    <td colSpan={tableColumns?.length || 11}>{intl.formatMessage({ id: 'GENERAL.NODATA1' })}</td>
                                </tr>
                        }
                    </tbody>
                    :
                    <tbody>
                        <tr className='w-100 '>
                            <td colSpan={20} className='w-100 h-100'>
                                <Skeleton height={20} style={{ margin: '7px 0' }} count={5} width="100%" containerClassName="w-100" />
                            </td>
                        </tr>
                    </tbody>
                }

                {/* end::Table body */}
            </table>
            {/* end::Table */}
        </div>
    )
}


export { CustomTable }