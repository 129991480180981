import {FaStar} from '@react-icons/all-files/fa/FaStar'

const root =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEV

export const api = {
  root:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL_DEV,
  filesRoot:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL_DEV,
  admin: {
    companies: {
      list: `${root}/admin/company/list`,
      get: `${root}/admin/company/get`,
      save: `${root}/admin/company/save`,
      savePrintHeader: `${root}/admin/company/file/save`,
    },
    users: {
      list: `${root}/admin/user/list`,
      get: `${root}/admin/user/get`,
      save: `${root}/admin/user/save`,
      delete: `${root}/admin/user/delete`,
      listPermissions: `${root}/admin/user/permission/list`,
      listUserPermissions: `${root}/admin/user/permission/get`,
      saveUserPermissions: `${root}/admin/user/permission/save`,
    },
    carModels: {
      list: `${root}/car/model/list`,
      get: `${root}/car/model/get`,
      save: `${root}/car/model/save`,
      delete: `${root}/car/model/delete`,
    },
    rentalSpots: {
      list: `${root}/core/spot/list`,
      get: `${root}/core/spot/get`,
      save: `${root}/core/spot/save`,
      delete: `${root}/core/spot/delete`,
    },
    blacklist: {
      list: `${root}/core/blacklist/list`,
      get: `${root}/core/blacklist/get`,
      save: `${root}/core/blacklist/add`,
      delete: `${root}/core/blacklist/remove`,
    },
  },
  auth: {
    login: `${root}/member/login`,
    register: `${root}/member/register`,
    getUsers: `${root}/member/GetPaged`,
    getUser: `${root}/member/GetUser`,
    getUserByToken: `${root}/member/GetUserByToken`,
    refreshToken: `${root}/member/refresh`,
    confirmOTP: `${root}/member/otp/confirm`,
    resendOTP: `${root}/member/otp/resend`,
  },
  general: {
    getNotifications: `${root}/core/NotificationsGet`,
    downloadFile: `${root}/core/file/download`,
    listEmployees: `${root}/core/user/list`,
    deleteFile: `${root}/core/file/delete`,
    getRentalSpots: `${root}/core/spot/list`,
    getContractInsuranceTypes: `${root}/core/insurance/type/list`,
    getContractRentalTypes: `${root}/core/rental/type/list`,
    getPaymentMethods: `${root}/core/payment/method/list`,
  },
  renter: {
    get: `${root}/renter/get`,
    save: `${root}/renter/save`,
    list: `${root}/renter/list`,
    delete: `${root}/renter/delete`,
    addToBlacklist: `${root}/admin/blacklist/add`,
  },
  car: {
    get: `${root}/car/get`,
    save: `${root}/car/save`,
    list: `${root}/car/list`,
    delete: `${root}/car/delete`,
    changeStatus: `${root}/car/status/change`,
    listModels: `${root}/car/models/list`,
    listExtras: `${root}/car/extras/list`,
    testPost: `${root}/car/testPost`,
    testGet: `${root}/car/testGet`,
  },
  contract: {
    get: `${root}/contract/get`,
    save: `${root}/contract/save`,
    list: `${root}/contract/list`,
    delete: `${root}/contract/delete`,
    cancel: `${root}/contract/cancel`,
    saveNote: `${root}/contract/note/save`,
    listPayments: `${root}/contract/payment/list`,
    getInsurance: `${root}/contract/insurance/get`,
    getRenters: `${root}/renter/list`,
    closeContract: `${root}/contract/close`,
    getPayment: `${root}/contract/payment/get`,
    deletePayment: `${root}/contract/payment/delete`,
    savePayment: `${root}/contract/payment/save`,
  },
  partialContract: {
    get: `${root}/partialContract/get`,
    save: `${root}/partialContract/save`,
    list: `${root}/partialContract/list`,
    delete: `${root}/partialContract/delete`,
    cancel: `${root}/partialContract/cancel`,
    saveNote: `${root}/partialContract/note/save`,
    getRenters: `${root}/renter/list`,
    closeContract: `${root}/partialContract/close`,
  },
  ticket: {
    get: `${root}/home/trafficTickets/get`,
    save: `${root}/home/trafficTickets/save`,
    list: `${root}/home/trafficTickets/list`,
    delete: `${root}/home/trafficTickets/delete`,
    approve: `${root}/home/trafficTickets/approve`,
    refreshGovernorateTickets: `${root}/home/carseer/refresh`,
    checkContract: `${root}/contract/get`,
  },
  reservations: {
    get: `${root}/reservations/get`,
    save: `${root}/reservations/save`,
    list: `${root}/reservations/list`,
    delete: `${root}/reservations/delete`,
    close: `${root}/reservations/close`,
    cancel: `${root}/reservations/cancel`,
  },
  insurance: {
    get: `${root}/contract/insurances/get`,
    save: `${root}/contract/insurances/save`,
    list: `${root}/contract/insurances/list`,
    delete: `${root}/contract/insurances/delete`,
    refund: `${root}/contract/insurances/refund`,
  },
  bank: {
    get: `${root}/accounting/banks/get`,
    save: `${root}/accounting/banks/save`,
    list: `${root}/accounting/banks/list`,
    delete: `${root}/accounting/banks/delete`,
  },
  bond: {
    get: `${root}/accounting/bonds/get`,
    save: `${root}/accounting/bonds/save`,
    list: `${root}/accounting/bonds/list`,
    delete: `${root}/accounting/bonds/delete`,
    generate: `${root}/accounting/bonds/generate`,
    dailyStatement: `${root}/accounting/statement/daily`,
    cycleBalance: `${root}/accounting/balance/cycle`,
  },
  bill: {
    get: `${root}/accounting/bills/get`,
    save: `${root}/accounting/bills/save`,
    list: `${root}/accounting/bills/list`,
    delete: `${root}/accounting/bills/delete`,
  },
  check: {
    get: `${root}/accounting/checks/get`,
    save: `${root}/accounting/checks/save`,
    list: `${root}/accounting/checks/list`,
    delete: `${root}/accounting/checks/delete`,
    cancel: `${root}/accounting/checks/status/update`,
    getBanks: `${root}/accounting/checks/banks/list`,
    payedToHistory: `${root}/accounting/checks/payedTo/list`,
  },
  reports: {
    getOfficeBudget: `${root}/accounting/reports/office/budget`,
    getCarsBudget: `${root}/accounting/reports/cars/budget`,
    getTax: `${root}/accounting/reports/tax`,
  },
  user: {
    getStats: `${root}/member/user/stats`,
    save: `${root}/member/save`,
  },
  home: {
    contractmonthlyStats: `${root}/home/contract/monthlyStats`,
    rentermonthlyStats: `${root}/home/renter/monthlyStats`,
    paymentmonthlyStats: `${root}/home/payment/monthlyStats`,
    newContracts: `${root}/home/contract/new`,
    returnedCars: `${root}/home/car/returned`,
    availableCars: `${root}/home/car/available`,
    trafficTickets: `${root}/home/traffic/tickets`,
    rentersDues: `${root}/home/renter/dues/list`,
    securityReport: `${root}/home/security/report`,
    generalStats: `${root}/home/stats/general`,
    expiredLincenses: `${root}/home/stats/expiredLicenses`,
  },
  settings: {
    saveSettings: `${root}/settings/company/save`,
    getCompanyInfo: `${root}/settings/company/get`,
  },
  maintinance: {
    get: `${root}/home/maintinance/get`,
    save: `${root}/home/maintinance/save`,
    list: `${root}/home/maintinance/list`,
    delete: `${root}/home/maintinance/delete`,
  },
  employee: {
    get: `${root}/employee/get`,
    save: `${root}/employee/save`,
    list: `${root}/employee/list`,
    delete: `${root}/employee/delete`,
    listDepartments: `${root}/department/list`,
  },
  employeesContracts: {
    get: `${root}/employee/contract/get`,
    save: `${root}/employee/contract/save`,
    list: `${root}/employee/contract/list`,
    delete: `${root}/employee/contract/delete`,
    listDepartments: `${root}/department/list`,
  },
  notifications: {
    hub: `${root}/notifications/hub`,
    list: `${root}/notifications/list`,
    test: `${root}/notifications/test`,
    testOrg: `${root}/notifications/org/test`,
  },
}

export const constants = {
  tax: 16,
  appTitle: 'Kensoftware',
  appLink: 'https://www.kensoftware.com',
  appAboutLink: 'https://www.kensoftware.com/About',
  appContactLink: 'https://www.kensoftware.com/Contact',
  defaultCurrency: 'JOD',
  employmentMinimumSalary: 260,
  quickAccess: {
    items: [
      {
        statsTitle: 'WIDGETS.WIDGET1TITLE',
        statsTotal: 'CONTRACT.TOTALCONTRACTS',
        links: [
          {
            label: function () {
              return constants.menuItems.operations.items.Cars.title
            },
            path: function () {
              return constants.menuItems.operations.items.Cars.path
            },
            icon: {
              type: 'fontAwesome',
              path: 'fa fa-car fs-3x',
            },
            statsAttr: 'totalCars',
          },
          {
            label: function () {
              return constants.menuItems.admin.items.Users.title
            },
            path: function () {
              return constants.menuItems.admin.items.Users.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/communication/com006.svg',
            },
            statsAttr: 'totalUsers',
          },
          {
            label: function () {
              return constants.menuItems.operations.items.Contracts.title
            },
            path: function () {
              return constants.menuItems.operations.items.Contracts.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalContracts',
          },
          {
            label: function () {
              return constants.menuItems.operations.items.Renters.title
            },
            path: function () {
              return constants.menuItems.operations.items.Renters.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/communication/com005.svg',
            },
            statsAttr: 'totalRenters',
          },
          {
            label: function () {
              return constants.menuItems.operations.items.Maintinance.title
            },
            path: function () {
              return constants.menuItems.operations.items.Maintinance.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs008.svg',
            },
            statsAttr: 'totalMaintinanceRecords',
          },
          {
            label: function () {
              return constants.menuItems.operations.items.ContractInsurances.title
            },
            path: function () {
              return constants.menuItems.operations.items.ContractInsurances.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/coding/cod007.svg',
            },
            statsAttr: 'totalInsurancesRecords',
          },
        ],
      },
      {
        statsTitle: 'WIDGETS.WIDGET2TITLE',
        statsTotal: 'RENTER.TOTALRENTERS',
        links: [
          {
            label: function () {
              return constants.menuItems.reports.items.AvailableCars.title
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalAvailableCars',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.TrafficTickets.title
            },
            path: function () {
              return constants.menuItems.operations.items.TrafficTickets.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalTrafficTickets',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.ReturnedCars.title
            },
            dynVar: 'period',
            dynVarValue: 'GENERAL.TODAY',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalTodayReturnedCars',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.ReturnedCars.title
            },
            dynVar: 'period',
            dynVarValue: 'GENERAL.PERDATE',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
          },
          {
            label: function () {
              return constants.menuItems.admin.items.SecurityReport.title
            },
            path: function () {
              return constants.menuItems.admin.items.SecurityReport.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/maps/map001.svg',
            },
            statsAttr: 'totalSecurityStatementRecords',
          },
          {
            label: function () {
              return constants.menuItems.admin.items.Blacklist.title
            },
            path: function () {
              return constants.menuItems.admin.items.Blacklist.path
            },
            icon: {
              type: 'component',
              path: () => (
                <FaStar
                  className='w-20px w-md-35px h-20px h-md-35px'
                  color='#ffe234'
                  fill='#ffe234'
                  style={{borderRadius: '50%', margin: '0 5px'}}
                />
              ),
            },
            statsAttr: 'totalBlacklistRecords',
          },
        ],
      },
      {
        statsTitle: 'WIDGETS.WIDGET3TITLE',
        statsTotal: 'PAYMENTS.TOTALPAYMENTS',
        links: [
          {
            label: function () {
              return constants.menuItems.reports.items.NewContracts.title
            },
            dynVar: 'status',
            dynVarValue: 'CONTRACT.OPENED',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalTodayOpenContracts',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.NewContracts.title
            },
            dynVar: 'status',
            dynVarValue: 'CONTRACT.CLOSED1',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalTodayClosedContracts',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.RentersDues.title
            },
            dynVar: 'target',
            dynVarValue: 'USER.STATS.CLOSEDCONTRACTS',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalOpenContractsDues',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.RentersDues.title
            },
            dynVar: 'target',
            dynVarValue: 'USER.STATS.OPENCONTRACTS',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/abstract/abs027.svg',
            },
            statsAttr: 'totalClosedContractsDues',
          },
          {
            label: function () {
              return constants.menuItems.operations.items.Reservations.title
            },
            path: function () {
              return constants.menuItems.operations.items.Reservations.path
            },
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/files/fil002.svg',
            },
            statsAttr: 'totalReservations',
          },
          {
            label: function () {
              return constants.menuItems.reports.items.ExpiredLicensesCars.title
            },
            dynVar: 'status',
            dynVarValue: 'REPORTS.EXPIREDLICESNSES',
            icon: {
              type: 'svg',
              path: '/media/icons/duotune/general/gen013.svg',
            },
            statsAttr: 'totalExpiredCarsLicenses',
          },
        ],
      },
    ],
  },
  months: [
    {
      ar: 'كانون الثاني',
      en: 'January',
    },
    {
      ar: 'شباط',
      en: 'February',
    },
    {
      ar: 'آذار',
      en: 'March',
    },
    {
      ar: 'نيسان',
      en: 'April',
    },
    {
      ar: 'أيار',
      en: 'May',
    },
    {
      ar: 'حزيران',
      en: 'June',
    },
    {
      ar: 'تموز',
      en: 'July',
    },
    {
      ar: 'آب',
      en: 'August',
    },
    {
      ar: 'أيلول',
      en: 'September',
    },
    {
      ar: 'تشرين الأول',
      en: 'October',
    },
    {
      ar: 'تشرين الثاني',
      en: 'Novermber',
    },
    {
      ar: 'كانون الأول',
      en: 'Decembers',
    },
  ],
  userLevel: {
    SuperAdmin: 0,
    Admin: 8,
    Employee: 16,
    User: 32,
  },
  menuItems: {
    dashboard: {
      path: '/dashboard',
      permission: 'DashboardSection',
      title: 'MENU.DASHBOARD',
      level: 8,
      icon: '/media/icons/duotune/art/art002.svg',
    },
    settings: {
      path: '/settings',
      permission: 'SettingsSection',
      sectionTitle: 'SETTINGS.SECTIONTITLE',
      title: 'MENU.SETTINGS',
      level: 16,
      icon: '/media/icons/duotune/art/art002.svg',
      items: {
        Settings: {
          path: '/settings',
          permission: 'GetCompanyInfo',
          title: 'SETTINGS.SECTIONTITLE',
          hasBullet: true,
          level: 16,
        },
      },
    },
    admin: {
      path: '/admin',
      permission: 'AdminSection',
      sectionTitle: 'ADMIN.SECTIONTITLE',
      title: 'ADMIN.TITLE',
      level: 8,
      icon: '/media/icons/duotune/general/gen022.svg',
      items: {
        Companies: {
          path: '/admin/companies',
          permission: 'ListCompany',
          title: 'ADMIN.COMPANIES',
          hasBullet: true,
          level: 0,
        },
        Users: {
          path: '/admin/users',
          permission: 'ListUser',
          title: 'ADMIN.USERS',
          hasBullet: true,
          level: 8,
        },
        CarModels: {
          path: '/admin/car/models',
          permission: 'ListCarModel',
          title: 'ADMIN.CARMODELS',
          hasBullet: true,
        },
        Spots: {
          path: '/admin/spot/list',
          permission: 'ListSpot',
          title: 'ADMIN.PICKUP_DROPOFF_PLACES',
          hasBullet: true,
        },
        Blacklist: {
          path: '/admin/blacklist',
          permission: 'ListBlacklist',
          title: 'ADMIN.BLACKLIST',
          hasBullet: true,
          level: 8,
        },
        SecurityReport: {
          path: '/admin/security/report',
          permission: 'SecurityReport',
          title: 'ADMIN.SECURITYREPORT',
          hasBullet: true,
          level: 16,
        },
        OutOfServiceCars: {
          path: '/admin/cars/outOfService',
          permission: 'ListOutOfServiceCars',
          title: 'ADMIN.OUTOFSERVICECARS',
          hasBullet: true,
        },
      },
    },
    operations: {
      path: '/operations',
      permission: 'OperationsSection',
      level: 16,
      title: 'MENU.Operations',
      sectionTitle: 'OPERATION.SECTIONTITLE',
      icon: '/media/icons/duotune/layouts/lay002.svg',
      items: {
        Renters: {
          level: 16,
          path: '/operations/renters',
          permission: 'ListRenter',
          title: 'MENU.Renters',
        },
        Contracts: {
          level: 16,
          path: '/operations/contracts',
          permission: 'ListContract',
          title: 'MENU.CarsContracts',
        },
        PartialContracts: {
          level: 16,
          path: '/operations/p/contracts',
          permission: 'ListPartialContract',
          title: 'MENU.CarsContracts',
        },
        Cars: {
          level: 16,
          path: '/operations/cars',
          permission: 'ListCar',
          title: 'MENU.Cars',
        },
        TrafficTickets: {
          level: 16,
          path: '/operations/trafficTickets',
          permission: 'ListTrafficTickets',
          title: 'MENU.TRAFFICTICKETS',
        },
        ContractInsurances: {
          level: 16,
          path: '/operations/insurances',
          permission: 'ListContractInsurances',
          title: 'MENU.INSURANCES',
        },
        Reservations: {
          level: 16,
          path: '/operations/reservations',
          permission: 'ListReservations',
          title: 'MENU.RESERVATIONS',
        },
        Maintinance: {
          level: 16,
          path: '/operations/maintinance',
          permission: 'ListMaintinanceRecords',
          title: 'MAINTINANCE.SECTIONTITLE',
        },
      },
    },
    reports: {
      show: false,
      path: '/reports',
      permission: '',
      level: 16,
      title: 'MENU.REPORTS',
      sectionTitle: 'REPORTS.SECTIONTITLE',
      icon: '/media/icons/duotune/layouts/lay002.svg',
      items: {
        AvailableCars: {
          path: '/reports/cars/available',
          permission: 'ListAvailableCars',
          title: 'MENU.AVAILABLECARS',
        },
        ReturnedCars: {
          path: '/reports/cars/returned',
          permission: 'ListReturnedCars',
          title: 'MENU.RETURNEDCARS',
        },
        NewContracts: {
          show: false,
          path: '/reports/contracts/new',
          permission: 'ListNewContracts',
          title: 'MENU.NEWCONTRACTS',
        },
        TrafficTickets: {
          path: '/reports/trafficTickets',
          permission: 'ListTrafficTickets',
          title: 'MENU.TRAFFICTICKETS',
        },
        RentersDues: {
          path: '/reports/renters/dues',
          permission: 'ListRentersDues',
          title: 'MENU.RENTERSDUES',
        },
        ExpiredLicensesCars: {
          show: false,
          path: '/operations/cars',
          permission: 'ListCars',
          title: 'REPORTS.EXPIREDLICESNSES',
        },
      },
    },
    accounting: {
      path: '/accounting',
      permission: 'AccountingSection',
      level: 16,
      title: 'MENU.Accounting',
      sectionTitle: 'ACCOUNTING.SECTIONTITLE',
      icon: '/media/icons/duotune/layouts/lay002.svg',
      items: {
        Bonds: {
          path: '/accounting/bonds',
          permission: 'ListBonds',
          title: 'MENU.BONDS',
        },
        Bills: {
          path: '/accounting/bills',
          permission: 'ListBills',
          title: 'MENU.BILLS',
        },

        Banks: {
          path: '/accounting/banks',
          permission: 'ListBanks',
          title: 'MENU.BANKS',
        },
        Checks: {
          path: '/accounting/checks',
          permission: 'ListChecks',
          title: 'MENU.CHECKS',
        },
        DailyStatement: {
          path: '/accounting/bonds/statement/daily',
          permission: 'DailyStatement',
          title: 'BONDS.DAILYSTATEMENT',
        },
        Reports: {
          path: '/accounting/reports',
          permission: 'ListReports',
          title: 'MENU.REPORTS',
        },
      },
    },
    hr: {
      path: '/hr',
      permission: 'HRSection',
      level: 16,
      title: 'MENU.HRSECTION',
      sectionTitle: 'HR.SECTIONTITLE',
      icon: '/media/icons/duotune/layouts/lay002.svg',
      items: {
        Employees: {
          level: 16,
          path: '/hr/employees',
          permission: 'ListEmployees',
          title: 'MENU.EMPLOYEES',
        },
        Contracts: {
          level: 16,
          path: '/hr/employees/contracts',
          permission: 'ListEmploymentContracts',
          title: 'MENU.EMPLOYMENTCONTRACTS',
        },
        Reports: {
          level: 16,
          path: '/hr/reports',
          permission: 'HrReports',
          title: 'MENU.HRReports',
        },
      },
    },
  },
}
