import {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../modules/home'
const ContractTemplate = lazy(
  () => import('./../../_metronic/layout/components/print/Templates/Contract')
)

export function PrintRoutes({baseUrl}: any) {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/settings/print/template/contract' exact component={ContractTemplate} />
        <Redirect to={baseUrl || '/dashboard'} />
      </Switch>
    </Suspense>
  )
}
