import axios, { CancelToken } from 'axios'
import { TicketModel } from '../models/TicketModel'
import { IRenter } from '../../Renters/models/RenterModel'
import { ICar } from '../../Cars/models/CarModel'
import { api } from '../../../../../constants'
import { queryBuilder } from '../../../../helpers/GeneralHelper'
import { Contract } from '../../Contracts/models/ContractModel'
import { User } from '../../../admin/models/AdminModels'

export const LIST_RENTERS_URL = `${api.renter.list}`
export const CHECK_CONTRACT_URL = `${api.ticket.checkContract}`
export const LIST_URL = `${api.ticket.list}`
export const SAVE_URL = `${api.ticket.save}`
export const GET_URL = `${api.ticket.get}`
export const DELETE_URL = `${api.ticket.delete}`
export const APPROVE_URL = `${api.ticket.approve}`
export const DOWNLOADFILE_URL = `${api.general.downloadFile}`
export const DELETEFILE_URL = `${api.general.deleteFile}`
export const LIST_USERS_URL = `${api.general.listEmployees}`
export const REFRESH_GOVERNORATE_TICKETS_URL = `${api.ticket.refreshGovernorateTickets}`

export const FILES_URL = `${api.filesRoot}`
// Server should return AuthModel
export function create(ticket: TicketModel, cancelToken?: CancelToken) {
  const dataForm = new FormData()
  Object.entries(ticket).map(([key, value]) => {
    if (value && typeof value !== undefined && value !== 'null' && value !== 'undefined') {
      if (key === 'attachments') {
        for (let index = 0; index < value.length; index++) {
          dataForm.append(key, value[index])
        }
      } else if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
          Object.entries(value[index]).map(([key1, value1]) => {
            if (
              value1 &&
              typeof value1 !== undefined &&
              value1 !== 'null' &&
              value1 !== 'undefined'
            ) {
              dataForm.append(key + '[' + index + '][' + key1 + ']', value1 as string)
            }
          })
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        Object.entries(value).map(([key1, value1]) => {
          if (
            value1 &&
            typeof value1 !== undefined &&
            value1 !== 'null' &&
            value1 !== 'undefined'
          ) {
            dataForm.append(key + '[' + key1 + ']', value1 as string)
          }
        })
        //dataForm.append(key,JSON.stringify(value))
      } else {
        dataForm.append(key, value)
      }
    }
  })
  return axios.post(SAVE_URL, dataForm, {
    headers: { 'Content-Type': 'multipart/form-data', cancelToken },
  })
}
export function listUsers(cancelToken?: CancelToken) {
  return axios.get<User[]>(`${LIST_USERS_URL}`, { cancelToken })
}
export function get(id: string, cancelToken?: CancelToken) {
  return axios.get<TicketModel>(GET_URL + ('?Id=' + id), { cancelToken })
}

export function list(page?: number, pageSize?: number, filters?: any, cancelToken?: CancelToken) {
  let query = queryBuilder(page, pageSize, filters)
  return axios.get<{ results: TicketModel[]; total: number, totalPaid: number, totalNotPaid: number }>(`${LIST_URL}${query}`, { cancelToken })
}

export function refreshGovernorateTickets(cancelToken?: CancelToken) {
  return axios.post<any>(`${REFRESH_GOVERNORATE_TICKETS_URL}`, { cancelToken })
}

export function doDelete(id: number, cancelToken?: CancelToken) {
  return axios.post(DELETE_URL, id, { headers: { 'Content-Type': 'application/json', cancelToken } })
}
export function getRenters(
  page?: number,
  pageSize?: number,
  filters?: any,
  cancelToken?: CancelToken
) {
  let query = queryBuilder(page, pageSize, filters)
  return axios.get<{ results: IRenter[]; total: number }>(`${LIST_RENTERS_URL}${query}`, {
    cancelToken,
  })
}

export function doDeleteAttachment(path: string, cancelToken?: CancelToken) {
  const dataForm = new FormData()
  dataForm.append('path', path)
  return axios.post(DELETEFILE_URL, dataForm, { cancelToken })
}

export function doApprove(id: number, cancelToken?: CancelToken) {
  return axios.post(APPROVE_URL, id, { headers: { 'Content-Type': 'application/json', cancelToken } })
}

export function getFile(path: string, fileName: string, cancelToken?: CancelToken) {
  return axios({
    url: DOWNLOADFILE_URL + ('?path=' + path),
    method: 'GET',
    responseType: 'blob', // important,
    cancelToken,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}

export function toApiPath(path: String) {
  return FILES_URL + path
}

export function checkContract(date: string, carNumber: number | null) {
  return axios.get<{ contract: Contract; renter: IRenter; car: ICar }>(
    `${CHECK_CONTRACT_URL}?Filters.Date=${date}&Filters.CarNumber=${carNumber || ''}`
  )
}


