import { api } from '../../../../constants'
import axios, { CancelToken } from 'axios'
import { UserModel } from '../../auth/models/UserModel'
import { UserStats } from './../models/User'
import { queryBuilder } from '../../../helpers/GeneralHelper'
import { Notification } from '../../admin/components/notifications/models/Notification'



export const SAVE_USER_URL = `${api.user.save}`
export const GETUSERSTATS_URL = `${api.user.getStats}`
export const GET_NOTIFICATIONS_URL = `${api.notifications.list}`
export const TEST_NOTIFICATIONS_URL = `${api.notifications.test}`
export const TEST_ORG_NOTIFICATIONS_URL = `${api.notifications.testOrg}`

export function save(user: UserModel) {
    const dataForm = new FormData();
    Object.entries(user).map(([key, value]) => {
        if (value && typeof value !== undefined && value !== 'null' && value !== 'undefined') {
            if (key === 'attachments') {
                for (let index = 0; index < value.length; index++) {
                    dataForm.append(key, value[index])
                }
            }
            else if (Array.isArray(value)) {
                for (let index = 0; index < value.length; index++) {
                    Object.entries(value[index]).map(([key1, value1]) => {
                        if (value1 && typeof value1 !== undefined && value1 !== 'null' && value1 !== 'undefined') {
                            dataForm.append(key + '[' + index + '][' + key1 + ']', value1 as string)
                        }
                    })

                }
            }
            else if (key === 'image') {
                dataForm.append(key, value)
            }
            else if (typeof value === 'object' && !Array.isArray(value)) {
                Object.entries(value).map(([key1, value1]) => {
                    if (value1 && typeof value1 !== undefined && value1 !== 'null' && value1 !== 'undefined') {
                        dataForm.append(key + '[' + key1 + ']', value1 as string)
                    }
                })
                //dataForm.append(key,JSON.stringify(value))
            }
            else {
                dataForm.append(key, value)
            }
        }

    })
    return axios.post(SAVE_USER_URL, dataForm, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export function getStats(id: string) {
    return axios.get<UserStats>(GETUSERSTATS_URL + `?id=${id}`)
}


export function getNotifications(
    page?: number,
    pageSize?: number,
    filters?: any,
    cancelToken?: CancelToken
) {
    let query = queryBuilder(page, pageSize, filters)
    return axios.get<{ notifications: Notification[]; total: number, totalUnread: number }>(`${GET_NOTIFICATIONS_URL}${query}`, { cancelToken })
}


export function testNotifications() {
    return axios.post<string>(TEST_NOTIFICATIONS_URL)
}

export function testOrgNotifications() {
    return axios.post<string>(TEST_ORG_NOTIFICATIONS_URL)
}

