import {FC, Suspense, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {notify} from '../../helpers/GeneralHelper'
import {FallbackView, Widget1, Widget2} from './components'
import {CustomModal} from './components/CustomModal'
import {DatePickerModal} from './components/DatePickerModal'
import {getGeneralStats} from './redux/HOMECRUD'
import {Redirect, Route, Switch} from 'react-router-dom'

const DashboardPage: FC = () => {
  const [type, setType] = useState<number | undefined>(undefined)
  const [subType, setSubType] = useState<number | undefined>(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [generalStats, setGeneralStats] = useState<any>(undefined)
  const intl = useIntl()
  useEffect(() => {
    if (type) {
      if (type === 2 && subType === 4) {
        setDateModalOpen(true)
      } else {
        setModalOpen(true)
      }
    }

    getGeneralStats()
      .then((response) => {
        setGeneralStats(response.data)
      })
      .catch(() => {
        notify({
          title: intl.formatMessage({id: 'GENERAL.ERRORTITLE'}),
          text: intl.formatMessage({id: `GENERAL.ERROR.GENERALERROR`}),
          icon: 'error',
        })
      })
  }, [type])

  useEffect(() => {
    if (date) {
      if (subType === 4) {
        setModalOpen(true)
      }
    }
  }, [date])

  const getModalTitle = () => {
    switch (type) {
      case 2:
        switch (subType) {
          case 1:
            return intl.formatMessage({id: 'MENU.AVAILABLECARS'})
          case 2:
            return intl.formatMessage({id: 'MENU.TRAFFICTICKETS'})
          case 3:
            return intl.formatMessage(
              {id: 'MENU.RETURNEDCARS'},
              {period: intl.formatMessage({id: 'GENERAL.TODAY'})}
            )
          case 4:
            return intl.formatMessage(
              {id: 'MENU.RETURNEDCARS'},
              {period: intl.formatMessage({id: 'GENERAL.PERDATE'})}
            )
          default:
            return intl.formatMessage({id: 'MENU.AVAILABLECARS'})
        }
      case 3:
        switch (subType) {
          case 1:
            return intl.formatMessage(
              {id: 'MENU.NEWCONTRACTS'},
              {status: intl.formatMessage({id: 'CONTRACT.OPENED'})}
            )
          case 2:
            return intl.formatMessage(
              {id: 'MENU.NEWCONTRACTS'},
              {status: intl.formatMessage({id: 'CONTRACT.CLOSED1'})}
            )
          case 3:
            return intl.formatMessage(
              {id: 'MENU.RENTERSDUES'},
              {target: intl.formatMessage({id: 'USER.STATS.CLOSEDCONTRACTS'})}
            )
          case 4:
            return intl.formatMessage(
              {id: 'MENU.RENTERSDUES'},
              {target: intl.formatMessage({id: 'USER.STATS.OPENCONTRACTS'})}
            )
          case 5:
            return intl.formatMessage(
              {id: 'MENU.RENTERSDUES'},
              {target: intl.formatMessage({id: 'USER.STATS.OPENCONTRACTS'})}
            )
          case 6:
            return intl.formatMessage({id: 'REPORTS.EXPIREDLICESNSES'})
          default:
            return intl.formatMessage({id: 'MENU.AVAILABLECARS'})
        }

      default:
        return intl.formatMessage({id: 'MENU.AVAILABLECARS'})
    }
  }

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8 no-print'>
        <div className='col-xxl-4'>
          <Widget1
            type={1}
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
            handleClick={(type, sub) => console.log('')}
            statsData={generalStats}
          />
        </div>
        <div className='col-xxl-4'>
          <Widget1
            type={2}
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            strokeColor='#cb1e46'
            handleClick={(type, sub) => {
              setSubType(sub)
              setType(type)
            }}
            statsData={generalStats}
          />
        </div>
        <div className='col-xxl-4'>
          <Widget1
            type={3}
            className='card-xl-stretch mb-xl-8'
            chartColor='info'
            chartHeight='200px'
            strokeColor='#cb1e46'
            handleClick={(type, sub) => {
              setSubType(sub)
              setType(type)
            }}
            statsData={generalStats}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'></div>
        <div className='col-xl-8'></div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'></div>
        <div className='col-xl-4'></div>
        <div className='col-xl-4'></div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'></div>
        <div className='col-xxl-8'></div>
      </div>
      {type && (
        <CustomModal
          type={type}
          subtype={subType}
          open={modalOpen}
          onHide={() => {
            setModalOpen(false)
            setDateModalOpen(false)
            setType(undefined)
            setSubType(undefined)
            setDate(undefined)
          }}
          size='xl'
          title={getModalTitle()}
          date={date}
        />
      )}
      {dateModalOpen && (
        <DatePickerModal
          open={dateModalOpen}
          onHide={() => {
            setModalOpen(false)
            setDateModalOpen(false)
            setType(undefined)
            setSubType(undefined)
            setDate(undefined)
          }}
          handleSelectDate={(date: any) => setDate(date)}
        />
      )}
    </>
  )
}

const Home: FC = () => {
  const intl = useIntl()

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' exact>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
          <DashboardPage />
        </Route>
        <Route path='/' exact>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
          <DashboardPage />
        </Route>
        <Redirect to={'/dashboard'} />
      </Switch>
    </Suspense>
  )
}
export default Home
export {Home}
