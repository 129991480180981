/* eslint-disable react/jsx-no-target-blank */
import React, {FC, useRef, useEffect, useState, Fragment} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {HasPermission} from '../../../../app/modules/auth'
import {constants} from '../../../../constants'
interface props {
  user: UserModel
}
export const AsideMenuMain: FC<props> = ({user}) => {
  const [currentUser, setCurrentUser] = useState<UserModel>(user)
  const intl = useIntl()
  useEffect(() => {
    setCurrentUser(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      {Object.values(constants.menuItems).map((item: any, i: number) => {
        if (item.items && item.show !== false) {
          return (
            <HasPermission key={`menu_list_${i}`} permission={item.permission} level={item.level}>
              <Fragment key={`menu_list_${i}_${i}`}>
                <div className='menu-item no-print'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {intl.formatMessage({id: item.sectionTitle})}
                    </span>
                  </div>
                </div>
                <AsideMenuItemWithSub
                  key={`menu_list_${i}_${i}_${i}`}
                  to={item.path}
                  title={intl.formatMessage({id: item.title})}
                  fontIcon='bi-archive'
                  icon={item.icon}
                >
                  {Object.values(item.items).map(
                    (subItem: any, i: number) =>
                      subItem.show !== false && (
                        // @ts-ignore
                        <HasPermission
                          key={`menu_list_item_${i}`}
                          permission={subItem.permission}
                          level={subItem.level}
                        >
                          <AsideMenuItem
                            key={`menu_list_item_${i}_${i}`}
                            to={subItem.path}
                            title={intl.formatMessage({id: subItem.title})}
                            hasBullet={subItem.hasBullet}
                          />
                        </HasPermission>
                      )
                  )}
                </AsideMenuItemWithSub>
              </Fragment>
            </HasPermission>
          )
        } else {
          return (
            item.show !== false && (
              <HasPermission key={`menu_list_${i}`} permission={item.permission} level={item.level}>
                <Fragment key={`menu_list_${i}_${i}`}>
                  {item.sectionTitle && (
                    <div className='menu-item'>
                      <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                          {intl.formatMessage({id: item.sectionTitle})}
                        </span>
                      </div>
                    </div>
                  )}
                  <AsideMenuItem
                    to={item.path}
                    icon={item.icon}
                    title={intl.formatMessage({id: item.title})}
                    fontIcon='bi-app-indicator'
                  />
                </Fragment>
              </HasPermission>
            )
          )
        }
      })}
    </>
  )
}
