import { getErrors } from '../../app/helpers/GeneralHelper'

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { token },
      } = store.getState()

      let lang = localStorage.getItem(process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      if (lang) {

        try {
          const parsed = JSON.parse(lang);
          console.log("lang", parsed)
          lang = parsed?.selectedLang === "en" ? "en-US" : "ar-JO"
        } catch (error) {
          lang = "ar-JO"
        }
      }
      config.headers["Accept-Language"] = lang
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      // Do something with response data waseem here to  custemize the response for all responses
      switch (response?.status || 200) {
        case 204: // no content means not modified or no data
          return Promise.reject('NOCONTENT')
        default:
          return response
      }
    },
    (error: any) => {
      // Do something with response error waseem here to  custemize the response error for all responses
      let err = getErrors(error)
      return Promise.reject(err)
    }
  )
}
