/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {KTSVG, toAbsoluteUrl, toImageUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {ReactReduxContext} from 'react-redux'
import {useTheme} from '../../ThemeProvider/ThemeProvider'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const theme = useTheme()
  const isCustomTheme = theme === 'custom'
  const {header, aside} = config
  const dispatch = useDispatch()
  // @ts-ignore
  const {store} = useContext(ReactReduxContext)
  const [user, setUser] = useState<UserModel>(store.getState().auth.user)
  return (
    <div
      id='kt_header'
      className={clsx('header no-print', classes.header.join(' '), 'align-items-stretch')}
      style={{background: isCustomTheme ? config.headerBackground ?? '' : ''}}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            {/* @ts-ignore */}
            <Link to='/dashboard' className='d-lg-none'>
              {/* <img alt='Logo' className='h-30px logo'
                src={toImageUrl(`/organizations/${user?.organizationId || ''}/${user?.organizationId || ''}.png`)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = toImageUrl(`/defaultLogo.png`)
                }}
              /> */}
              <h1 className='h-25px logo asideLogo no-print mb-0 mx-2 '>{user?.companyName}</h1>
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            {/* @ts-ignore */}
            <Link to='/' className='d-lg-none'>
              {/* <img alt='Logo'
                className='logo'
                src={toImageUrl(`/organizations/${user?.organizationId || ''}/${user?.organizationId || ''}.png`)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = toImageUrl(`/defaultLogo.png`)
                }}
              /> */}
              <h1 className='h-25px logo asideLogo no-print mb-0 mx-2'>{user?.companyName}</h1>
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
