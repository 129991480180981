/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import Swal, {SweetAlertIcon} from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useIntl} from 'react-intl'
import CountUp, {useCountUp} from 'react-countup'
import {getMonthlyStats} from '../redux/HOMECRUD'
import {constants} from '../../../../constants'
import {useLang} from '../../../../_metronic/i18n/Appi18n'
import {Link} from 'react-router-dom'
import {Button, Spinner, Tooltip} from 'react-bootstrap-v5'
import {NotifyMessage} from '../../../helpers/GeneralModels'
import {refreshGovernorateTickets} from '../../Operations/Tickets/redux/TicketCRUD'
import axios from 'axios'
type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
  type: number
  handleClick: (type: number, sub: number) => void
  statsData?: any
}

let toReadCounters: any = {
  totalAvailableCars: 0,
  totalCars: 0,
  totalClosedContractsDues: 0,
  totalContracts: 0,
  totalOpenContractsDues: 0,
  totalRenters: 0,
  totalTodayClosedContracts: 0,
  totalTodayOpenContracts: 0,
  totalTodayReturnedCars: 0,
  totalTrafficTickets: 0,
  totalUsers: 0,
}

export class GeneralStats {
  totalAvailableCars: number = 0
  totalCars: number = 0
  totalClosedContractsDues: number = 0
  totalContracts: number = 0
  totalOpenContractsDues: number = 0
  totalRenters: number = 0
  totalTodayClosedContracts: number = 0
  totalTodayOpenContracts: number = 0
  totalTodayReturnedCars: number = 0
  totalTrafficTickets: number = 0
  totalUsers: number = 0
}

const Widget1: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  strokeColor,
  type,
  handleClick,
  statsData,
}) => {
  const lang = useLang()
  const [stats, setStats] = useState<any[]>([])
  const [generalStats, setGeneralStats] = useState<any>(new GeneralStats())
  const [loadingTickets, setLoadingTickets] = useState<boolean>(false) // status of the tickets list

  let interval: any = null

  const [categories, setCategories] = useState<any[]>([])
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const cancelSource = useMemo(() => axios.CancelToken.source(), [])

  useEffect(() => {
    if (!!statsData) {
      setGeneralStats(statsData)
    }
    return () => {
      clearInterval(interval!)
    }
  }, [statsData])

  useEffect(() => {
    setTimeout(() => {
      clearInterval(interval!)
    }, 10000)

    return () => {
      clearInterval(interval!)
    }
  }, [])

  useEffect(() => {
    getMonthlyStats(type)
      .then((res) => {
        setStats((res.data.totals as any[]) || [0])
        setCategories((res.data.months as any[]) || [0])
      })
      .catch((error) => {
        if (error.message) {
          notify({
            title: intl.formatMessage({id: 'GENERAL.ERRORTITLE'}),
            text: intl.formatMessage(
              {id: `GENERAL.ERROR.${error.message}`},
              {params: error.params || ''}
            ),
            icon: 'error',
          })
        }
      })

    return () => {
      clearInterval(interval!)
    }
  }, [])

  const getResourceMessage = (id: string, variable?: string, variableValue?: string) => {
    if (variable) {
      return intl.formatMessage({id}, {[variable]: intl.formatMessage({id: variableValue})})
    } else {
      return intl.formatMessage({id})
    }
  }

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const chartOptions = (
      chartHeight: string,
      chartColor: string,
      strokeColor: string,
      data: any[],
      categories: any[]
    ): ApexOptions => {
      const labelColor = getCSSVariableValue('--bs-darkgray-500')
      const borderColor = getCSSVariableValue('--bs-darkgray-200')
      const color = getCSSVariableValue('--bs-' + 'white')

      return {
        series: [
          {
            name: getResourceMessage(constants.quickAccess.items[type - 1].statsTotal),
            data: data,
          },
        ],
        chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: chartHeight,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 5,
            left: 0,
            blur: 3,
            color: 'darkgray',
            opacity: 0.5,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'solid',
          opacity: 0,
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: ['darkgray'],
        },
        xaxis: {
          categories: categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
          crosshairs: {
            show: false,
            position: 'front',
            stroke: {
              color: borderColor,
              width: 1,
              dashArray: 3,
            },
          },
        },
        yaxis: {
          min: 0,
          max: [...(stats || [0])]?.sort().reverse()[0] || 100,
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val.toString()
            },
          },
          x: {
            formatter: function (val) {
              return constants.months[val - 1][lang]
            },
          },
          marker: {
            show: false,
          },
        },
        colors: ['transparent'],
        markers: {
          colors: [color],
          strokeColors: [strokeColor],
          strokeWidth: 3,
        },
      }
    }
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor, stats, categories)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats])

  /*************************** show notice popup ****************************/
  const notify = (message: NotifyMessage) => {
    Swal.fire({
      title: message.title,
      text: message.text,
      icon: message.icon as SweetAlertIcon,
      showConfirmButton: false,
      showCancelButton: message.showCancelButton,
      showCloseButton: message.showCloseButton,
      confirmButtonText: message.confirmButtonText,
      cancelButtonText: message.cancelButtonText,
      backdrop: message.backdrop,
      focusConfirm: message.focusConfirm,
      focusCancel: message.focusCancel,
      timer: 5000,
    })
  }
  /*************************** end show notice popup ****************************/

  const doRefreshGovernorateTickets = (e: any) => {
    e.preventDefault()
    setLoadingTickets(true)
    refreshGovernorateTickets(cancelSource.token)
      .then((response) => {
        notify({
          title: intl.formatMessage({id: 'TRAFFICTICKETS.SYNCSUCCESS'}),
          text: '',
          icon: 'success',
        })
        setLoadingTickets(false)
      })
      .catch((error) => {
        setLoadingTickets(false)
        if (error.message) {
          notify({
            title: intl.formatMessage({id: 'GENERAL.ERRORTITLE'}),
            text: intl.formatMessage({id: `GENERAL.ERROR.GENERALERROR`}),
            icon: 'error',
          })
        }
      })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title fw-bolder text-white'>
          {getResourceMessage(constants.quickAccess.items[type - 1].statsTitle)}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[0].path ? (
              <Link
                className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7 hoverable fw-bolder d-flex flex-column gap-1'
                to={constants.quickAccess.items[type - 1].links[0].path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[0].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[0].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[0].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[0].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[0].icon.path}
                      className='svg-icon-3x svg-icon-warning d-block my-2'
                    />
                  )}

                  {!!constants.quickAccess?.items[type - 1]?.links[0]?.statsAttr && (
                    <h1 className='display-5 text-warning'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[0]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[0].label(),
                    constants.quickAccess.items[type - 1].links[0].dynVar,
                    constants.quickAccess.items[type - 1].links[0].dynVarValue
                  )}
                </p>
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7 hoverable d-flex flex-column gap-1'
                onClick={() => handleClick(type, 1)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[0].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[0].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[0].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[0].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[0].icon.path}
                      className='svg-icon-3x svg-icon-warning d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[0]?.statsAttr && (
                    <h1 className='display-5 text-warning'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[0]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[0].label(),
                    constants.quickAccess.items[type - 1].links[0].dynVar,
                    constants.quickAccess.items[type - 1].links[0].dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[1].path ? (
              <Link
                className='col bg-light-primary px-6 py-8 rounded-2 mb-7 hoverable fw-bolder  d-flex flex-column gap-1'
                style={{minHeight: '160px'}}
                to={constants.quickAccess.items[type - 1].links[1].path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[1].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[1].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[1].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[1].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[1].icon.path}
                      className='svg-icon-3x svg-icon-primary d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[1]?.statsAttr && (
                    <h1 className='display-5 text-primary'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[1]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[1].label(),
                    constants.quickAccess.items[type - 1].links[1].dynVar,
                    constants.quickAccess.items[type - 1].links[1].dynVarValue
                  )}
                </p>
                {constants.quickAccess?.items[type - 1]?.links[1]?.statsAttr ===
                  'totalTrafficTickets' && (
                  <div
                    title={intl.formatMessage({id: 'TRAFFICTICKETS.SYNCWITHGOV'})}
                    className='d-flex align-items-center justify-content-center align-self-end'
                  >
                    {loadingTickets ? (
                      <Spinner size='sm' animation='border' color='white' />
                    ) : (
                      <i
                        className='bi bi-arrow-clockwise fs-2 text-primary cursor-pointer bf-transparent'
                        onClick={doRefreshGovernorateTickets}
                      ></i>
                    )}
                  </div>
                )}
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-primary px-6 py-8 rounded-2 mb-7 hoverable d-flex flex-column gap-1 '
                style={{minHeight: '160px'}}
                onClick={() => handleClick(type, 2)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[1].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[1].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[1].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[1].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[1].icon.path}
                      className='svg-icon-3x svg-icon-primary d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[1]?.statsAttr && (
                    <h1 className='display-5  text-primary'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[1]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[1].label(),
                    constants.quickAccess.items[type - 1].links[1].dynVar,
                    constants.quickAccess.items[type - 1].links[1].dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[2].path ? (
              <Link
                className='col bg-light-danger px-6 py-8 rounded-2 me-7 hoverable fw-bolder d-flex flex-column gap-1'
                to={constants.quickAccess.items[type - 1].links[2].path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[2].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[2].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[2].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[2].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[2].icon.path}
                      className='svg-icon-3x svg-icon-danger d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[2]?.statsAttr && (
                    <h1 className='display-5 text-danger'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[2]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[2].label(),
                    constants.quickAccess.items[type - 1].links[2].dynVar,
                    constants.quickAccess.items[type - 1].links[2].dynVarValue
                  )}
                </p>
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-danger px-6 py-8 rounded-2 me-7 hoverable d-flex flex-column gap-1'
                onClick={() => handleClick(type, 3)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[2].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[2].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[2].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[2].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[2].icon.path}
                      className='svg-icon-3x svg-icon-danger d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[2]?.statsAttr && (
                    <h1 className='display-5 text-danger'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[2]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>

                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[2].label(),
                    constants.quickAccess.items[type - 1].links[2].dynVar,
                    constants.quickAccess.items[type - 1].links[2].dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[3]?.path ? (
              <Link
                className='col bg-light-success px-6 py-8 rounded-2 hoverable fw-bolder d-flex flex-column gap-1'
                style={{minHeight: '160px'}}
                to={constants.quickAccess.items[type - 1].links[3]?.path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[3].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[3].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[3].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[3].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[3].icon.path}
                      className='svg-icon-3x svg-icon-success d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[3]?.statsAttr && (
                    <h1 className='display-5 text-success'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[3]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(constants.quickAccess.items[type - 1].links[3].label())}
                </p>
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-success px-6 py-8 rounded-2 hoverable d-flex flex-column gap-1'
                style={{minHeight: '160px'}}
                onClick={() => handleClick(type, 4)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[3].icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[3].icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[3].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[3].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[3].icon.path}
                      className='svg-icon-3x svg-icon-success d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[3]?.statsAttr && (
                    <h1 className='display-5 text-success'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[3]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>

                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[3].label(),
                    constants.quickAccess.items[type - 1].links[3].dynVar,
                    constants.quickAccess.items[type - 1].links[3].dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[4]?.path ? (
              <Link
                className='col bg-light-danger px-6 py-8 rounded-2 mt-7 me-7 hoverable fw-bolder d-flex flex-column gap-1'
                to={constants.quickAccess.items[type - 1].links[4].path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[4]?.icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[4]?.icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[4].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[4].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[4].icon.path}
                      className='svg-icon-3x svg-icon-danger d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[4]?.statsAttr && (
                    <h1 className='display-5 text-danger'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[4]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[4]?.label(),
                    constants.quickAccess.items[type - 1].links[4]?.dynVar,
                    constants.quickAccess.items[type - 1].links[4]?.dynVarValue
                  )}
                </p>
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-danger px-6 py-8 rounded-2 mt-7 me-7 hoverable d-flex flex-column gap-1'
                onClick={() => handleClick(type, 5)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[4]?.icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[4]?.icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[4].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[4].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[4].icon.path}
                      className='svg-icon-3x svg-icon-danger d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[4]?.statsAttr && (
                    <h1 className='display-5 text-danger'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[4]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>

                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[4]?.label(),
                    constants.quickAccess.items[type - 1].links[4]?.dynVar,
                    constants.quickAccess.items[type - 1].links[4]?.dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
            {/* begin::Col */}
            {constants.quickAccess.items[type - 1].links[5]?.path ? (
              <Link
                className='col bg-light-success px-6 py-8 mt-7 rounded-2 hoverable fw-bolder d-flex flex-column gap-1'
                style={{minHeight: '160px'}}
                to={constants.quickAccess.items[type - 1].links[5].path()}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[5]?.icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[5]?.icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[5].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[5].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[5]?.icon.path}
                      className='svg-icon-3x svg-icon-success d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[5]?.statsAttr && (
                    <h1 className='display-5 text-success'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[5]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(constants.quickAccess.items[type - 1].links[5]?.label())}
                </p>
              </Link>
            ) : (
              <Button
                bsPrefix='btnsss'
                as='a'
                className='col bg-light-success mt-7 px-6 py-8 rounded-2 hoverable d-flex flex-column gap-1'
                style={{minHeight: '160px'}}
                onClick={() => handleClick(type, 6)}
              >
                <div className='d-flex justify-content-between align-items-start gap-1 w-100'>
                  {constants.quickAccess.items[type - 1].links[5]?.icon.type === 'fontAwesome' ? (
                    <i
                      className={`${
                        constants.quickAccess.items[type - 1].links[5]?.icon.path
                      } svg-icon-3x text-warning d-block my-2`}
                    ></i>
                  ) : constants.quickAccess.items[type - 1].links[5].icon.type === 'component' ? (
                    constants.quickAccess.items[type - 1].links[5].icon.path()
                  ) : (
                    <KTSVG
                      path={constants.quickAccess.items[type - 1].links[5]?.icon.path}
                      className='svg-icon-3x svg-icon-success d-block my-2'
                    />
                  )}
                  {!!constants.quickAccess?.items[type - 1]?.links[5]?.statsAttr && (
                    <h1 className='display-5 text-success'>
                      <CountUp
                        end={
                          generalStats[constants.quickAccess?.items[type - 1]?.links[5]?.statsAttr!]
                        }
                      />
                    </h1>
                  )}
                </div>
                <p className='text-dark fw-bolder fs-6'>
                  {getResourceMessage(
                    constants.quickAccess.items[type - 1].links[5]?.label(),
                    constants.quickAccess.items[type - 1].links[5]?.dynVar,
                    constants.quickAccess.items[type - 1].links[5]?.dynVarValue
                  )}
                </p>
              </Button>
            )}
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Widget1}
