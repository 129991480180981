/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {confirmOTP, login, resendOTP} from '../redux/AuthCRUD'
import {useIntl} from 'react-intl'
import toast from 'react-hot-toast'

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showOTP, setShowOTP] = useState(false)

  const [timeLeft, setTimeLeft] = useState(60) // 60 seconds countdown
  const [isResendEnabled, setIsResendEnabled] = useState(false)

  const dispatch = useDispatch()
  const intl = useIntl()
  const formRef = useRef()

  useEffect(() => {
    // If timeLeft is 0, enable the resend button
    if (timeLeft === 0) {
      setIsResendEnabled(true)
      return
    }

    // Set up the countdown timer
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    // Cleanup the interval on component unmount
    return () => clearInterval(timerId)
  }, [timeLeft])

  let schemaObj: any = {
    email: !!showOTP
      ? Yup.string().nullable()
      : Yup.string()
          .email(
            intl.formatMessage(
              {id: 'AUTH.VALIDATION.INVALID'},
              {name: intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            )
          )
          .min(
            4,
            intl.formatMessage(
              {id: 'AUTH.VALIDATION.INVALID'},
              {name: intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            )
          )
          .max(
            100,
            intl.formatMessage(
              {id: 'AUTH.VALIDATION.INVALID'},
              {name: intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            )
          )
          .required(
            intl.formatMessage(
              {id: 'AUTH.VALIDATION.REQUIRED'},
              {name: intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            )
          ),
    password: !!showOTP
      ? Yup.string().nullable()
      : Yup.string().required(
          intl.formatMessage(
            {id: 'AUTH.VALIDATION.REQUIRED'},
            {name: intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          )
        ),
    otp: !showOTP
      ? Yup.string().nullable()
      : Yup.number()
          .min(4, `${intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD'})} 4`)
          .required(
            intl.formatMessage(
              {id: 'AUTH.VALIDATION.REQUIRED'},
              {name: intl.formatMessage({id: 'AUTH.INPUT.OTP'})}
            )
          ),
  }

  let initialValues: any = {
    email: '',
    password: '',
    otp: '',
  }

  const loginSchema = Yup.object().shape(schemaObj)

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        handleSubmit(values, {setStatus, setSubmitting})
      }, 1000)
    },
  })

  const handleSubmit = (values: any, {setStatus, setSubmitting}: any) => {
    if (!showOTP) {
      login(values.email, values.password)
        .then(({data}) => {
          setLoading(false)
          setSubmitting(false)
          if (data?.error) {
            toast.error(data?.error)
            return
          }
          if (!!data?.token) {
            toast(intl.formatMessage({id: 'AUTH.GENERAL.SUCCESSLOGIN'}))
            dispatch(auth.actions.login(data?.token))
          }
          //require OTP
          if (data === 2) {
            toast(intl.formatMessage({id: 'AUTH.GENERAL.OTPSENT'}))
            setShowOTP(true)
          }
        })
        .catch((e) => {
          console.log('login e', {e})
          toast.error(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}))
          setLoading(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_LOGIN'}))
        })
    } else {
      confirmOTP(values.email, `${values.otp}`)
        .then(({data}) => {
          setLoading(false)
          setSubmitting(false)
          if (data?.error) {
            toast.error(data?.error)
            return
          }
          if (!!data?.token) {
            dispatch(auth.actions.login(data?.token))
          }
        })
        .catch((e) => {
          console.log('confirmOTP e', {e})
          toast.error(e.message)
          setLoading(false)
          setSubmitting(false)
          setStatus(e.message)
        })
    }
  }

  const handleResendOTP = () => {
    setIsResendEnabled(false)
    setTimeLeft(60) // Reset the timer to 60 seconds
    resendOTP(formik.values.email)
      .then(({data}) => {
        setLoading(false)
        formik.setSubmitting(false)
        if (data?.error) {
          toast.error(data?.error)
          return
        }
        if (data === 2) {
          toast(intl.formatMessage({id: 'AUTH.GENERAL.OTPSENT'}))

          setShowOTP(true)
        }
      })
      .catch((e) => {
        console.log('resendOTP e', {e})
        toast.error(e.message)
        setLoading(false)
        formik.setSubmitting(false)
        formik.setStatus(e.message)
      })
  }

  const backToLogin = () => {
    formik.setFieldValue('otp', null)
    formik.resetForm()
    setShowOTP(false)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      //@ts-ignore
      ref={formRef}
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.Title'})}</h1>
      </div>

      {!showOTP && (
        <>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>
              {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
              {...formik.getFieldProps('email')}
              disabled={showOTP}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                  {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                </label>
                {/* end::Label */}
                {/* begin::Link  */}
                {/* @ts-ignore */}
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
                </Link>
                {/* end::Link */}
              </div>
            </div>
            <input
              type='password'
              autoComplete='off'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              {...formik.getFieldProps('password')}
              disabled={showOTP}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {showOTP && (
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {intl.formatMessage({id: 'AUTH.INPUT.OTP'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.INPUT.OTP'})}
            {...formik.getFieldProps('otp')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.otp && formik.errors.otp},
              {
                'is-valid': formik.touched.otp && !formik.errors.otp,
              }
            )}
            type='number'
            name='otp'
            autoComplete='off'
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.otp}</span>
            </div>
          )}
        </div>
      )}

      <div className='text-center d-flex align-items-center justify-content-center flex-column'>
        {showOTP && isResendEnabled ? (
          <a href='#' onClick={handleResendOTP} className='mb-2'>
            {intl.formatMessage({id: 'AUTH.INPUT.RESENDOTP'})}
          </a>
        ) : showOTP && !isResendEnabled ? (
          <p> {intl.formatMessage({id: 'AUTH.INPUT.RESENDOTPIN'}, {time: timeLeft})}</p>
        ) : (
          ''
        )}
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'GENERAL.Continue'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'GENERAL.PleaseWait'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {showOTP && (
          <a href='#' onClick={backToLogin} className='mt-3'>
            {intl.formatMessage({id: 'AUTH.LOGIN.BACK'})}
          </a>
        )}
      </div>
    </form>
  )
}
