import {toImageUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

export function FallbackView() {
  const intl = useIntl()
  return (
    <div className='splash-screen'>
      <img
        id='splash_image'
        src={toImageUrl('defaultLogo.png')}
        alt='Company logo'
        className='h-30px'
      />
      <span id='splash_text'>{intl.formatMessage({id: 'GENERAL.LOADING'})}</span>
    </div>
  )
}
