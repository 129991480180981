import {lazy, Suspense, useContext, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PrintRoutes} from './PrintRoutes'
import {ReactReduxContext} from 'react-redux'
import {FallbackView} from '../modules/home/components/FallBackView'

const Home = lazy(() => import('../modules/home'))
const SettingsPage = lazy(() => import('./../modules/Settings/Settings'))
const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

const RenterPage = lazy(() => import('../modules/Operations/Renters'))
const ContractPage = lazy(() => import('../modules/Operations/Contracts'))
const PartialContractPage = lazy(() => import('../modules/Operations/PartialContracts'))
const CarPage = lazy(() => import('../modules/Operations/Cars'))
const TicketPage = lazy(() => import('./../modules/Operations/Tickets'))
const ContractInsurancesPage = lazy(() => import('./../modules/Operations/Insurance'))
const ReservationPage = lazy(() => import('./../modules/Operations/Reservations'))
const MaintinancePage = lazy(() => import('./../modules/Operations/Maintinance'))
const BondsPage = lazy(() => import('./../modules/Accounting/Bonds'))
const BillsPage = lazy(() => import('./../modules/Accounting/Bills'))
const BanksPage = lazy(() => import('./../modules/Accounting/Banks'))
const ChecksPage = lazy(() => import('./../modules/Accounting/Checks'))
const ReportsPage = lazy(() => import('./../modules/Accounting/Reports'))
const EmployeesPage = lazy(() => import('./../modules/HR/Employees'))
const EmployeesContractsPage = lazy(() => import('./../modules/HR/Contracts'))

const ProfilePage = lazy(() => import('./../modules/profile/ProfilePage'))

const routes = [
  {
    id: 'DashboardPage',
    permission: 'DashboardSection',
    url: '/dashboard',
    component: Home,
    exact: true,
  },
  {
    id: 'SettingsPage',
    permission: 'SettingsSection',
    url: '/settings',
    component: SettingsPage,
    exact: true,
  },
  {
    id: 'AdminPage',
    permission: 'AdminSection',
    url: '/admin',
    component: AdminPage,
    exact: false,
  },
  {
    id: 'RenterPage',
    permission: 'ListRenter',
    url: '/operations/renters',
    component: RenterPage,
    exact: true,
  },
  {
    id: 'ContractPage',
    permission: 'ListContract',
    url: '/operations/contracts',
    component: ContractPage,
    exact: true,
  },
  {
    id: 'PartialContractPage',
    permission: 'ListPartialContract',
    url: '/operations/p/contracts',
    component: PartialContractPage,
    exact: true,
  },
  {
    id: 'CarPage',
    permission: 'ListCar',
    url: '/operations/cars',
    component: CarPage,
    exact: true,
  },
  {
    id: 'TicketPage',
    permission: 'ListTrafficTickets',
    url: '/operations/trafficTickets',
    component: TicketPage,
    exact: true,
  },
  {
    id: 'ContractInsurancesPage',
    permission: 'ListContractInsurances',
    url: '/operations/insurances',
    component: ContractInsurancesPage,
    exact: true,
  },
  {
    id: 'ReservationPage',
    permission: 'ListReservations',
    url: '/operations/reservations',
    component: ReservationPage,
    exact: true,
  },
  {
    id: 'MaintinancePage',
    permission: 'ListMaintinanceRecords',
    url: '/operations/maintinance',
    component: MaintinancePage,
    exact: true,
  },
  {
    id: 'BondsPage',
    permission: 'ListBonds',
    url: '/accounting/bonds',
    component: BondsPage,
    exact: false,
  },
  {
    id: 'BillsPage',
    permission: 'ListBills',
    url: '/accounting/bills',
    component: BillsPage,
    exact: true,
  },
  {
    id: 'BanksPage',
    permission: 'ListBanks',
    url: '/accounting/banks',
    component: BanksPage,
    exact: true,
  },
  {
    id: 'ChecksPage',
    permission: 'ListChecks',
    url: '/accounting/checks',
    component: ChecksPage,
    exact: true,
  },
  {
    id: 'ReportsPage',
    permission: 'ListReports',
    url: '/accounting/reports',
    component: ReportsPage,
    exact: false,
  },
  {
    id: 'EmployeesPage',
    permission: 'ListEmployees',
    url: '/hr/employees',
    component: EmployeesPage,
    exact: true,
  },
  {
    id: 'EmployeesContractsPage',
    permission: 'ListEmploymentContracts',
    url: '/hr/employees/contracts',
    component: EmployeesContractsPage,
    exact: true,
  },
  {
    id: 'ProfilePage',
    permission: null,
    url: '/profile',
    component: ProfilePage,
    exact: true,
  },
]

export function PrivateRoutes() {
  // @ts-ignore
  const {store} = useContext(ReactReduxContext)
  const [permissions, _] = useState<any[]>(store.getState().auth.permissions)
  const [user, ___] = useState<any>(store.getState().auth.user)

  if (!permissions?.length) {
    return <></>
  }

  const filteredRoutes = routes.filter((r) => {
    if (!r.permission || (permissions?.length && permissions?.includes(r.permission))) {
      return true
    }
    return false
  })

  console.log('routes', {routes, filteredRoutes})

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {filteredRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.url}
              exact={route.exact}
              // @ts-ignore
              component={route.component}
            />
          )
        })}

        <PrintRoutes baseUrl={user?.baseUrl} />

        <Redirect from='/auth' to={user?.baseUrl || '/dashboard'} />
        <Redirect from='/auth/login' exact to={user?.baseUrl || '/dashboard'} />
        <Redirect exact from='/' to={user?.baseUrl || '/dashboard'} />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
