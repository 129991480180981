import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { Department } from '../../HR/models/HrModels'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    setDepartments: '[ListCars] Action'
}

export const initState: IAppState = {
    departments: [],
}

export interface IAppState {
    departments?: Department[]
}

export const reducer = persistReducer(
    {
        storage,
        key: process.env.REACT_APP_BASE_STORAGE_KEY_APP ?? 'CarsRental2022_App',
        whitelist: [
            'departments'
        ],
    },
    (state: IAppState = initState, action: ActionWithPayload<any>) => {
        switch (action.type) {
            case actionTypes.setDepartments: {
                const departments = action.payload
                return { ...state, departments }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setDepartments: (departments: Department[]) => ({ type: actionTypes.setDepartments, payload: departments })
}
