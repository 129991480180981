import axios from 'axios'
import { api } from '../../../../constants'
export const GET_CONTRACT_MONTHLYSTATS = `${api.home.contractmonthlyStats}`
export const GET_RENTER_MONTHLYSTATS = `${api.home.rentermonthlyStats}`
export const GET_PAYMENT_MONTHLYSTATS = `${api.home.paymentmonthlyStats}`

export const GET_NEW_CONTRACTS = `${api.home.newContracts}`
export const GET_RETURNED_CARS = `${api.home.returnedCars}`
export const GET_AVAILABLE_CARS = `${api.home.availableCars}`
export const GET_TRAFFIC_TICKETS = `${api.home.trafficTickets}`
export const GET_RENTERS_DUES = `${api.home.rentersDues}`
export const GET_GENERAL_STATS = `${api.home.generalStats}`
export const GET_EXPIRED_LICENSES = `${api.home.expiredLincenses}`
const Stat_URL = (type: number) => {
  switch (type) {
    case 1:
      return GET_CONTRACT_MONTHLYSTATS
    case 2:
      return GET_RENTER_MONTHLYSTATS
    case 3:
      return GET_PAYMENT_MONTHLYSTATS
    default:
      return GET_CONTRACT_MONTHLYSTATS
  }
}

const CUSTOM_LIST_URL = (type: number, subType: number) => {
  switch (type) {
    case 2:
      switch (subType) {
        case 1:
          return GET_AVAILABLE_CARS
        case 2:
          return GET_TRAFFIC_TICKETS
        case 3:
          return GET_RETURNED_CARS
        case 4:
          return GET_RETURNED_CARS
        default:
          return GET_AVAILABLE_CARS
      }
    case 3:
      switch (subType) {
        case 1:
          return GET_NEW_CONTRACTS
        case 2:
          return GET_NEW_CONTRACTS
        case 3:
          return GET_RENTERS_DUES
        case 4:
          return GET_RENTERS_DUES
        case 6:
          return GET_EXPIRED_LICENSES
        default:
          return GET_PAYMENT_MONTHLYSTATS
      }
    default:
      switch (subType) {
        case 1:
          return GET_AVAILABLE_CARS
        case 2:
          return GET_TRAFFIC_TICKETS
        case 3:
          return GET_RETURNED_CARS
        case 4:
          return GET_RETURNED_CARS
        default:
          return GET_AVAILABLE_CARS
      }
  }
}

export function getMonthlyStats(type: number) {
  return axios.get(Stat_URL(type))
}

export function getTodayContracts(status: boolean) {
  return axios.get(`${GET_CONTRACT_MONTHLYSTATS}?status=${status}`)
}

export function getGeneralStats() {
  return axios.get(`${GET_GENERAL_STATS}`)
}

export function customQuickAccessList(page: number, pageSize: number, type: number, subType: number, filter?: any) {
  let filters = ''
  if (filter) {
    filters = queryBuilder(page, pageSize, filter)
  }
  return axios.get(CUSTOM_LIST_URL(type, subType) + filters)
}

function queryBuilder(page?: number, pageSize?: number, keys?: any) {
  let query = `${page !== null && page != undefined ? `?Page=${page}` : '?'}${pageSize ? `&PageSize=${pageSize}` : ''
    }`
  Object.keys(keys).map((key: string, index: number) => {
    if (key !== undefined && key !== null) {
      if (keys[key] !== undefined) {
        if (index === 0 && page === null && page === undefined) {
          query += `${key}=${keys[key]}`
        } else {
          query += `&${key}=${keys[key]}`
        }
      }
    }
  })

  return query
}
