import React, {FC, createContext, useContext, Suspense, lazy} from 'react'
import {LayoutSplashScreen} from '../layout/core'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'en' | 'ar'
}
const initialState: Props = {
  selectedLang: 'ar',
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}
const RTLStyle = lazy(() => import('./RTLTheme'))
const LTRStyle = lazy(() => import('./LTRTheme'))
const AppI18nProvider: FC = ({children}) => {
  const lang = getConfig()
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {lang.selectedLang === 'ar' && <RTLStyle />}
      {lang.selectedLang === 'en' && <LTRStyle />}
      <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
    </Suspense>
  )
}

export {AppI18nProvider, useLang}
