import axios from 'axios'
import Swal, { SweetAlertIcon } from 'sweetalert2'
import { NotifyMessage } from './GeneralModels'
import moment from 'moment'
import { IntlShape } from 'react-intl'

export function setLoadingSpinner(loading: boolean) {
  if (loading) {
    document.getElementById('loading-spinner')?.classList.remove('d-none')
    document.getElementById('loading-spinner')?.classList.add('overlay-block')
    document.getElementById('loading-spinner')?.classList.add('overlay')
  } else {
    document.getElementById('loading-spinner')?.classList.add('d-none')
    document.getElementById('loading-spinner')?.classList.remove('overlay-block')
    document.getElementById('loading-spinner')?.classList.remove('overlay')
  }
}

export function queryBuilder(page?: number, pageSize?: number, keys?: any) {
  let query = `${page !== null && page != undefined ? `?Page=${page}` : ''}${pageSize ? `&PageSize=${pageSize}` : ''
    }`
  if (keys) {
    Object.keys(keys).map((key: string) => {
      if (keys[key] || keys[key] === 0 || keys[key] === false) {
        if (query === '?') {
          query += `Filters.${key}=${keys[key]}`
        } else {
          query += `&Filters.${key}=${keys[key]}`
        }
      }
    })
  }
  return query
}

export function getErrors(error: any) {
  if (error.response) {
    // The client was given an error response (5xx, 4xx)
    switch (error?.response?.status || 500) {
      case 400:
        let invalidParamters = ''
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).map((key: string, index: number) => {
            if (key !== '$') {
              invalidParamters += invalidParamters ? `,${key}` : key
            }
          })
        }
        return {
          message: invalidParamters ? 'INVALIDPARAMETERS' : 'BADREQUEST',
          params: invalidParamters,
        }
      case 401:
        return { message: 'NOTAUTHORIZED' }
      case 403:
        return { message: 'NOTAUTHORIZED' }
      case 404:
        return { message: 'NOTFOUND' }
      case 406:
        return { message: 'NOTACCEPTED' }
      case 408:
        return { message: 'BADREQUEST' }

      case 409:
        return { message: 'CONFLICT' }
      case 412:
        return { message: 'PRECONDITIONFAILED' }
      default:
        return { message: 'GENERALERROR' }
    }
  } else if (error.request) {
    // The client never received a response, and the request was never left
    switch (error?.response?.status || 500) {
      case 400:
        let invalidParamters = ''
        console.log(error?.response?.data)
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).map((key: string, index: number) => {
            invalidParamters += invalidParamters ? `,${key}` : key
          })
        }
        return {
          message: invalidParamters ? 'INVALIDPARAMETERS' : 'BADREQUEST',
          params: invalidParamters,
        }
      case 401:
        return { message: 'NOTAUTHORIZED' }
      case 403:
        return { message: 'NOTAUTHORIZED' }
      case 404:
        return { message: 'NOTFOUND' }
      case 406:
        return { message: 'NOTACCEPTED' }
      case 408:
        return { message: 'BADREQUEST' }
      case 409:
        return { message: 'CONFLICT' }
      case 412:
        return { message: 'PRECONDITIONFAILED' }
      default:
        return { message: 'GENERALERROR' }
    }
  } else {
    if (axios.isCancel(error)) {
      console.log('request has been canceled', error)
      return { message: '' } // no error to avoid pop up for user
    } else {
      console.log('unknown error')
      return { message: 'GENERALERROR' }
    }
  }
}



/*************************** show notice popup ****************************/
export const notify = (message: NotifyMessage) => {
  Swal.fire({
    title: message.title,
    text: message.text,
    icon: message.icon as SweetAlertIcon,
    showConfirmButton: false,
    showCancelButton: message.showCancelButton,
    showCloseButton: message.showCloseButton,
    confirmButtonText: message.confirmButtonText,
    cancelButtonText: message.cancelButtonText,
    backdrop: message.backdrop,
    focusConfirm: message.focusConfirm,
    focusCancel: message.focusCancel,
    timer: 5000
  })
}
/*************************** end show notice popup ****************************/

/*************************** handle get base64 image ****************************/
export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({ name: file.name, file, url: reader.result });
  reader.onerror = error => reject(error);
});
/*************************** end handle get base64 image ****************************/



export function formatNotificationDate(isoString: string, intl: IntlShape) {
  const momentDate = moment(isoString);
  const now = moment();

  const diffInDays = now.diff(momentDate, 'days');

  if (diffInDays >= 7) {
    // More than a day ago, display in "dd MM yyyy HH:mm" format
    return momentDate.format("DD MM YYYY HH:mm");
  } else {
    // Less than a day ago, display how long ago it was
    const diffInSeconds = now.diff(momentDate, 'seconds');

    if (diffInSeconds == 0) {
      return intl.formatMessage({ id: "GENERAL.JUSTNOW" });
    }

    if (diffInSeconds < 60) {
      return intl.formatMessage({ id: "GENERAL.SECONDSAGO" }).replace("{{seconds}}", `${diffInSeconds}`);
    }

    const diffInMinutes = now.diff(momentDate, 'minutes');

    if (diffInMinutes < 60) {
      return intl.formatMessage({ id: "GENERAL.MINUTESAGO" }).replace("{{minutes}}", `${diffInMinutes}`);
    }

    const diffInHours = now.diff(momentDate, 'hours');

    if (diffInHours < 24) {
      return intl.formatMessage({ id: "GENERAL.HOURSAGO" }).replace("{{hours}}", `${diffInHours}`);
    }


    return intl.formatMessage({ id: "GENERAL.DAYSAGO" }).replace("{{days}}", `${diffInDays}`);
  }
}


export function isWithinLast24Hours(date: string) {
  const now = moment();
  const givenDate = moment(date);

  return now.diff(givenDate, 'hours') <= 24;
}