import axios, { CancelToken } from 'axios'
import {
  Contract,
  Spot,
  ContractInsuranceType,
  ContractRentalType,
  PaymentMethod,
  ContractPayment,
} from '../models/ContractModel'
import { IRenter } from '../../Renters/models/RenterModel'
import { ICar } from '../../Cars/models/CarModel'
import { api } from '../../../../../constants'
import { queryBuilder } from '../../../../helpers/GeneralHelper'
import { User } from '../../../admin/models/AdminModels'

export const LIST_RENTERS_URL = `${api.renter.list}`
export const LIST_CARS_URL = `${api.car.list}`
export const LIST_RENTAL_SPOTS_URL = `${api.general.getRentalSpots}`
export const LIST_RENTAL_TYPES_URL = `${api.general.getContractRentalTypes}`
export const LIST_PAYENTMETHODS_URL = `${api.general.getPaymentMethods}`
export const LIST_CONTRACT_INSURANCE_TYPES_URL = `${api.general.getContractInsuranceTypes}`
export const LIST_URL = `${api.contract.list}`
export const SAVE_URL = `${api.contract.save}`
export const GET_URL = `${api.contract.get}`
export const DELETE_URL = `${api.contract.delete}`
export const SAVE_NOTE_URL = `${api.contract.saveNote}`
export const CLOSE_URL = `${api.contract.closeContract}`
export const CANCEL_URL = `${api.contract.cancel}`
export const DOWNLOADFILE_URL = `${api.general.downloadFile}`
export const DELETEFILE_URL = `${api.general.deleteFile}`
export const FILES_URL = `${api.filesRoot}`
export const LIST_PAYMENTS_URL = `${api.contract.listPayments}`
export const GET_PAYMENT_URL = `${api.contract.getPayment}`
export const DELETE_PAYMENT_URL = `${api.contract.deletePayment}`
export const SAVE_PAYMENT_URL = `${api.contract.savePayment}`
export const LIST_USERS_URL = `${api.general.listEmployees}`
// Server should return AuthModel
export function create(contract: Contract, cancelToken?: CancelToken) {
  const dataForm = new FormData()
  Object.entries(contract).map(([key, value]) => {
    if (
      (value || value == 0) &&
      typeof value !== undefined &&
      value !== 'null' &&
      value !== 'undefined'
    ) {
      if (key === 'attachments') {
        for (let index = 0; index < value.length; index++) {
          dataForm.append(key, value[index])
        }
      } else if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
          Object.entries(value[index]).map(([key1, value1]) => {
            if (
              (value1 || value1 == 0) &&
              typeof value1 !== undefined &&
              value1 !== 'null' &&
              value1 !== 'undefined'
            ) {
              dataForm.append(key + '[' + index + '][' + key1 + ']', value1 as string)
            }
          })
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        Object.entries(value).map(([key1, value1]) => {
          if (
            value1 &&
            typeof value1 !== undefined &&
            value1 !== 'null' &&
            value1 !== 'undefined'
          ) {
            dataForm.append(key + '[' + key1 + ']', value1 as string)
          }
        })
        //dataForm.append(key,JSON.stringify(value))
      } else {
        dataForm.append(key, value)
      }
    }
  })
  return axios.post(SAVE_URL, dataForm, {
    headers: { 'Content-Type': 'multipart/form-data', cancelToken },
  })
}

export function SavePayment(contract: ContractPayment, cancelToken?: CancelToken) {
  const dataForm = new FormData()
  Object.entries(contract).map(([key, value]) => {
    if (value && typeof value !== undefined && value !== 'null' && value !== 'undefined') {
      if (key === 'attachments') {
        for (let index = 0; index < value.length; index++) {
          dataForm.append(key, value[index])
        }
      } else if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
          Object.entries(value[index]).map(([key1, value1]) => {
            if (
              value1 &&
              typeof value1 !== undefined &&
              value1 !== 'null' &&
              value1 !== 'undefined'
            ) {
              dataForm.append(key + '[' + index + '][' + key1 + ']', value1 as string)
            }
          })
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        Object.entries(value).map(([key1, value1]) => {
          if (
            value1 &&
            typeof value1 !== undefined &&
            value1 !== 'null' &&
            value1 !== 'undefined'
          ) {
            dataForm.append(key + '[' + key1 + ']', value1 as string)
          }
        })
        //dataForm.append(key,JSON.stringify(value))
      } else {
        dataForm.append(key, value)
      }
    }
  })
  return axios.post(SAVE_PAYMENT_URL, dataForm, {
    headers: { 'Content-Type': 'multipart/form-data', cancelToken },
  })
}

export function get(id: number, cancelToken?: CancelToken) {
  return axios.get<{ contract: Contract; renter: IRenter; car: ICar, totalTicketsAmount?: number }>(GET_URL + ('?Id=' + id), {
    cancelToken,
  })
}
export function getPayment(id: number, contractId: number, cancelToken?: CancelToken) {
  return axios.get<{ payment: ContractPayment; remainingAmount: number }>(
    GET_PAYMENT_URL + `?contractId=${contractId}&Id=${id}`,
    { cancelToken }
  )
}

export function getRenters(
  page?: number,
  pageSize?: number,
  filters?: any,
  cancelToken?: CancelToken
) {
  let query = queryBuilder(page, pageSize, filters)
  return axios.get<{ results: IRenter[]; total: number }>(`${LIST_RENTERS_URL}${query}`, {
    cancelToken,
  })
}

export function getRentalSpots(cancelToken?: CancelToken) {
  return axios.get<{ results: Spot[]; total: number }>(LIST_RENTAL_SPOTS_URL, { cancelToken })
}

export function deletePayment(id: number) {
  return axios.post(DELETE_PAYMENT_URL, id, { headers: { "Content-Type": 'application/json' } })
}

export function getContractInsuranceTypes(cancelToken?: CancelToken) {
  return axios.get<ContractInsuranceType[]>(LIST_CONTRACT_INSURANCE_TYPES_URL, { cancelToken })
}

export function getContractRentalTypes(cancelToken?: CancelToken) {
  return axios.get<ContractRentalType[]>(LIST_RENTAL_TYPES_URL, { cancelToken })
}

export function getContractPaymentMethods(cancelToken?: CancelToken) {
  return axios.get<PaymentMethod[]>(LIST_PAYENTMETHODS_URL, { cancelToken })
}

export function list(page?: number, pageSize?: number, filters?: any, cancelToken?: CancelToken) {
  let query = queryBuilder(page, pageSize, filters)
  return axios.get<{ results: Contract[]; total: number }>(`${LIST_URL}${query}`, { cancelToken })
}

export function doDelete(Id: string, cancelToken?: CancelToken) {
  return axios.post(DELETE_URL, Id, {
    headers: {
      'Content-Type': 'application/json',
      cancelToken,
    },
  })
}
export function doSaveNote(Id: string, note: string, cancelToken?: CancelToken) {
  return axios.post(SAVE_NOTE_URL, { Id: Id.toString(), note }, { cancelToken })
}
export function doCancel(Id: number, cancelToken?: CancelToken) {
  return axios.post(CANCEL_URL, Id, {
    headers: {
      'Content-Type': 'application/json',
      cancelToken,
    },
  })
}
export function doClose(Id: number, cancelToken?: CancelToken) {
  return axios.post(CLOSE_URL, Id, {
    headers: {
      'Content-Type': 'application/json',
      cancelToken,
    },
  })
}

export function listRentalCars(
  page?: number,
  pageSize?: number,
  filters?: any,
  cancelToken?: CancelToken
) {
  let query = queryBuilder(page, pageSize, filters)
  return axios.get<{ results: ICar[]; total: number }>(`${LIST_CARS_URL}${query}`, { cancelToken })
}

export function listPayments(id: number, cancelToken?: CancelToken) {
  return axios.get<{ payments: ContractPayment[]; remainingAmount: number }>(
    `${LIST_PAYMENTS_URL}?id=${id}`,
    { cancelToken }
  )
}

export function listUsers(cancelToken?: CancelToken) {
  return axios.get<User[]>(`${LIST_USERS_URL}`, { cancelToken })
}

export function doDeleteAttachment(path: string, cancelToken?: CancelToken) {
  const dataForm = new FormData()
  dataForm.append('path', path)
  return axios.post(DELETEFILE_URL, dataForm, { cancelToken })
}

export function getFile(path: string, fileName: string, cancelToken?: CancelToken) {
  return axios({
    url: DOWNLOADFILE_URL + ('?path=' + path),
    method: 'GET',
    responseType: 'blob', // important
    cancelToken,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}

export function toApiPath(path: String) {
  return FILES_URL + path
}
