import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  Spot,
  ContractInsuranceType,
  ContractRentalType,
  PaymentMethod,
} from '../models/ContractModel'
import { ICar } from '../../Cars/models/CarModel'
import { IRenter } from '../../Renters/models/RenterModel'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  listCars: '[ListCars] Action',
  listRenters: '[ListRenters] Action',
  listPaymentMethods: '[ListPaymentMethods] Action',
  listRentalTypes: '[ListRentalTypes] Action',
  listRentalSpots: '[ListRentalSpots] Action',
  listInsuranceTypes: '[listInsuranceTypes] Action',
  setPrintMethod: '[SetPrintMethod] Action',
  setTaxNumber: '[SetTaxNumber] Action',
}

const initialContractState: IContractState = {
  cars: undefined,
  renters: undefined,
  paymentMethods: undefined,
  rentalTypes: undefined,
  rentalSpots: undefined,
  insuranceTypes: undefined,
  printMethod: undefined,
  taxNumber: undefined,
}

export interface IContractState {
  cars?: ICar[]
  renters?: IRenter[]
  paymentMethods?: PaymentMethod[]
  rentalTypes?: ContractRentalType[]
  rentalSpots?: Spot[]
  insuranceTypes?: ContractInsuranceType[]
  printMethod?: number | undefined
  taxNumber?: number | undefined
}

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_BASE_STORAGE_KEY_APP ?? 'CarsRental2022_App',
    whitelist: [
      'cars',
      'renters',
      'paymentMethods',
      'rentalTypes',
      'rentalSpots',
      'insuranceTypes',
      'printMethod',
      'taxNumber',
    ],
  },
  (state: IContractState = initialContractState, action: ActionWithPayload<IContractState>) => {
    switch (action.type) {
      case actionTypes.listCars: {
        const cars = action.payload?.cars
        return { ...state, cars }
      }

      case actionTypes.listRenters: {
        const renters = action.payload?.renters
        return { ...state, renters }
      }

      case actionTypes.listPaymentMethods: {
        const paymentMethods = action.payload?.paymentMethods
        return { ...state, paymentMethods }
      }

      case actionTypes.listRentalTypes: {
        const rentalTypes = action.payload?.rentalTypes
        return { ...state, rentalTypes }
      }

      case actionTypes.listRentalSpots: {
        const rentalSpots = action.payload?.rentalSpots
        return { ...state, rentalSpots }
      }

      case actionTypes.listInsuranceTypes: {
        const insuranceTypes = action.payload?.insuranceTypes
        return { ...state, insuranceTypes }
      }
      case actionTypes.setPrintMethod: {
        const printMethod = action.payload?.printMethod
        return { ...state, printMethod }
      }

      case actionTypes.setTaxNumber: {
        const taxNumber = action.payload?.taxNumber
        return { ...state, taxNumber }
      }

      default:
        return state
    }
  }
)

export const actions = {
  listCars: (cars: ICar[]) => ({ type: actionTypes.listCars, payload: { cars } }),
  listRenters: (renters: IRenter[]) => ({ type: actionTypes.listRenters, payload: { renters } }),
  listPaymentMethods: (paymentMethods: PaymentMethod[]) => ({
    type: actionTypes.listPaymentMethods,
    payload: { paymentMethods },
  }),
  listRentalTypes: (rentalTypes: ContractRentalType[]) => ({
    type: actionTypes.listRentalTypes,
    payload: { rentalTypes },
  }),
  listRentalSpots: (rentalSpots: Spot[]) => ({
    type: actionTypes.listRentalSpots,
    payload: { rentalSpots },
  }),
  listInsuranceTypes: (insuranceTypes: ContractInsuranceType[]) => ({
    type: actionTypes.listInsuranceTypes,
    payload: { insuranceTypes },
  }),
  setPrintMethod: (printMethod: number) => ({
    type: actionTypes.setPrintMethod,
    payload: { printMethod },
  }),
  setTaxNumber: (taxNumber: number) => ({
    type: actionTypes.setTaxNumber,
    payload: { taxNumber },
  }),
}
