import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {AppI18nProvider} from './_metronic/i18n/Appi18n'

import {LayoutSplashScreen} from './_metronic/layout/core'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
//import './_metronic/assets/sass/style.scss'
//import './_metronic/assets/css/style.css'
//import './_metronic/assets/sass/style.react.scss'
//import './_metronic/assets/sass/style.dark.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic App mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */ //_redux.mockAxios(axios)
/**
 * Inject App interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

//Chart.register(...registerables)

ReactDOM.render(
  <Suspense fallback={<LayoutSplashScreen />}>
    <AppI18nProvider>
      {/* @ts-ignore */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        {/* @ts-ignore */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <App basename={PUBLIC_URL} />
        </PersistGate>
      </Provider>
    </AppI18nProvider>
  </Suspense>,
  document.getElementById('root')
)
