/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {list} from '../../Operations/Contracts/redux/ContractCRUD'

type Props = {
  className: string
  status: boolean
}

const Widget2: React.FC<Props> = ({className, status}) => {
  const intl = useIntl()
  const [contracts, setContracts] = useState([])
  useEffect(() => {
    list(0, 4, {Status: status}).then((res: any) => {
      setContracts(res.data.results)
    })
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>Activities</span>
          <span className='text-muted fw-bold fs-7'>890,344 Sales</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {contracts.map((contract: any, index: number) => (
            <div className='timeline-item'>
              {/* begin::Label */}
              <div className='timeline-label fw-bolder text-gray-800 fs-6'>
                {intl.formatTime(contract.createDate)}
              </div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i
                  className={`fa fa-genderless ${
                    index == 0
                      ? 'text-success'
                      : index == 1
                      ? 'text-warning'
                      : index == 3
                      ? 'text-danger'
                      : 'text-info'
                  } fs-1`}
                ></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='fw-mormal timeline-content text-muted ps-3'>{contract.id}</div>
              {/* end::Text */}
            </div>
          ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {Widget2}
