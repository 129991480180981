/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { useLang, setLanguage } from '../../../i18n/Appi18n'

const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
        lang: 'ar',
        name: 'العربية',
        flag: toAbsoluteUrl('/media/flags/jordan.svg'),
    },
]

const Language: FC = () => {
    const lang = useLang()
    const targetLanguage = languages.filter((x) => x.lang != lang)[0]
    return (
        <div
            className='h-fit-content align-self-center px-3'
            key={targetLanguage?.lang}
            onClick={() => {
                setLanguage(targetLanguage?.lang || 'en')
            }}
        >
            <a
                href='#'
                className={clsx('menu-link d-flex flex-row-reverse gap-3', { active: true })}
            >
                <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={targetLanguage?.flag} alt='app' />
                </span>
                {targetLanguage?.name}
            </a>
        </div>
    )
}

export { Language }
