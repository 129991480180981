import PropTypes from 'prop-types'
import {FC} from 'react'
import {connect} from 'react-redux'

const ShowForPermissionComponent = (props: any) => {
  let permissions = props.permission.includes(',') ? props.permission.split(',') : props.permission
  let couldShow = false
  if (Array.isArray(permissions)) {
    permissions.map((per: string) => {
      if (
        (!per || props.userPermissions.includes(per)) &&
        (isNaN(props.level) || props.userLevel <= props.level)
      ) {
        couldShow = true
      }
    })
  } else {
    couldShow =
      (!props.permission || props.userPermissions.includes(props.permission)) &&
      (isNaN(props.level) || props.userLevel <= props.level)
  }
  return couldShow && props.children
}

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  userPermissions: PropTypes.array.isRequired,
  userLevel: PropTypes.number.isRequired,
  key: PropTypes.number,
}

const mapStateToProps = (state: any) => {
  return {
    userPermissions: state?.auth.permissions || [],
    userLevel: !isNaN(state?.auth?.user?.level) ? state?.auth?.user?.level : 16,
  } //<--- here you will get permissions for your user from Redux store
}

export const HasPermission: FC<any> = connect(mapStateToProps)(
  ShowForPermissionComponent
) as FC<any>
