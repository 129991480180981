/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'

let rt: string = '/dashboard'
const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const location = useLocation()
  return (
    <>
      <Switch>
        {!isAuthorized ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from='/auth' exact={true} to={'/auth/login'} />
        )}

        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          <Redirect to={'/auth/login'} />
        ) : (
          <Switch>
            <Route>
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </Route>
          </Switch>
        )}
        <Redirect from='*' to={rt || '/'} />
        <Route path='/error' component={ErrorsPage} />
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
