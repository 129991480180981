import React, {FC, createContext, useContext, Suspense, lazy} from 'react'
import {LayoutSplashScreen} from '../core'
import loadable from '@loadable/component'
const THEME_CONFIG_KEY =
  process.env.REACT_APP_BASE_LAYOUT_THEME_CONFIG_KEY || 'CarsRental_Theme_2022'

type Props = {
  selectedTheme: 'dark' | 'light' | 'custom'
}
const initialState: Props = {
  selectedTheme: 'light',
}

function getConfig(): Props {
  const ls = localStorage.getItem(THEME_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error('unable to read theme from storage error : ', er)
      return initialState
    }
  } else {
    console.log('unable to read them from storage error : Not found')
    return initialState
  }
}

const ThemeContext = createContext<Props>(initialState)

const useTheme = () => {
  return useContext(ThemeContext).selectedTheme
}
export function setTheme(theme: string) {
  if (theme) {
    localStorage.setItem(THEME_CONFIG_KEY, JSON.stringify({selectedTheme: theme}))
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
}

const Light = loadable(() => import('./LightTheme'))
const Dark = loadable(() => import('./DarkTheme'))
const AppThemeProvider: FC = ({children}) => {
  const theme = getConfig()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {theme.selectedTheme === 'light' && <Light />}
      {theme.selectedTheme === 'custom' && <Light />}
      {theme.selectedTheme === 'dark' && <Dark />}
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    </Suspense>
  )
}

export {AppThemeProvider, useTheme}
