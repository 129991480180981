import { FC, lazy, useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap-v5";
import { useIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import Swal, { SweetAlertIcon } from 'sweetalert2'
import { createImportClause } from "typescript";
import { useLang } from "../../../../_metronic/i18n/Appi18n";
import { NotifyMessage } from "../../../helpers/GeneralModels";
import { printStyles } from "../../../helpers/StyleHelper";
import { customQuickAccessList } from "../redux/HOMECRUD";
import { CustomTable } from "./CustomTable";
var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

const CustomModal: FC<any> = (props: any) => {
    const [show, setShow] = useState(props.open)
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState((new Date(Date.now() - tzoffset)).toISOString().slice(0, -1))
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(5); // page size
    const intl = useIntl()
    const lang = useLang()
    const printRef = useRef(null);
    useEffect(() => {
        setShow(props.open)
    }, [props.open])

    useEffect(() => {
        if (!isNaN(props.type) && show) {
            let chosenDate = props.date ? new Date(props.date).toISOString() : date;
            let chosenStatus = props.type === 3 ? ((props.subtype === 2 || props.subtype === 3) ? true : false) : undefined;
            customQuickAccessList(0, 1000, props.type, props.subtype, { date: chosenDate, status: chosenStatus }).then((res: any) => {
                setData(res?.data?.results)
                setTotalPages(res?.data.total || 0)
                setLoading(false)
            }).catch((error: any) => {
                if (error.message) {
                    notify({
                        title: intl.formatMessage({ id: 'GENERAL.ERRORTITLE' }),
                        text: intl.formatMessage({ id: `GENERAL.ERROR.${error.message}` }, { params: error.params || '' }),
                        icon: 'error',
                    })
                }
            })
        }
    }, [props.type, show])

    useEffect(() => {
        if (!isNaN(props.type) && show) {
            let chosenDate = props.date ? new Date(props.date).toISOString() : date;
            let chosenStatus = props.type === 3 ? ((props.subtype === 2 || props.subtype === 3) ? true : false) : undefined;
            customQuickAccessList(currentPage, pageSize, props.type, props.subtype, { date: chosenDate, status: chosenStatus }).then((res: any) => {
                setData(res?.data?.results)
                setTotalPages(res?.data.total || 0)
                setLoading(false)
            }).catch((error: any) => {
                if (error.message) {
                    notify({
                        title: intl.formatMessage({ id: 'GENERAL.ERRORTITLE' }),
                        text: intl.formatMessage({ id: `GENERAL.ERROR.${error.message}` }, { params: error.params || '' }),
                        icon: 'error',
                    })
                }
            })
        }
    }, [currentPage, pageSize])


    /*************************** show notice popup ****************************/
    const notify = (message: NotifyMessage) => {
        Swal.fire({
            title: message.title,
            text: message.text,
            icon: message.icon as SweetAlertIcon,
            showConfirmButton: false,
            showCancelButton: message.showCancelButton,
            showCloseButton: message.showCloseButton,
            confirmButtonText: message.confirmButtonText,
            cancelButtonText: message.cancelButtonText,
            backdrop: message.backdrop,
            focusConfirm: message.focusConfirm,
            focusCancel: message.focusCancel,
            timer: 5000
        })
    }
    /*************************** end show notice popup ****************************/
    const sum = (items: any[], prop: string) => {
        try {
            return items.reduce(function (a, b) {
                return parseFloat(a) + parseFloat(b[prop]);
            }, 0);
        } catch (error) {
            return 0
        }

    };

    const printButton = () => {
        return (
            <ReactToPrint
                content={useCallback(() => {
                    return printRef.current
                }, [printRef.current])}
                documentTitle="AwesomeFileName"
                pageStyle={printStyles}
                removeAfterPrint
                trigger={reactToPrintTrigger}
            />
        )
    }

    const reactToPrintTrigger = useCallback(() => {
        return <Button className="no-print">{intl.formatMessage({ id: 'GENERAL.PRINT' })}</Button>;
    }, []);

    return (
        <Modal
            {...props}
            show={show}
            aria-labelledby="custom-modal-title"
            centered
        >
            <Modal.Header className=''>
                <Modal.Title id="custom-modal-title" className="">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="print-content-table-data" ref={printRef}>
                <h2 className="print-only w-100 text-center mb-3">{props.title}</h2>
                <CustomTable isLoading={loading} data={data} type={props.type} subtype={props.subtype} />
                {(props.subtype === 3 || props.subtype === 4) && props.type === 3 &&
                    <div className="d-flex align-items-center justify-content-end w-100">
                        <span className="w-25 d-flex align-items-center justify-content-center border-1 p-1 border border-dark rounded">
                            {`${intl.formatMessage({ id: 'GENERAL.TOTAL' })} : ${sum(data, "remaining")}`}
                        </span>
                    </div>

                }
            </Modal.Body>
            <Modal.Footer className='no-print'>
                <Button className="no-print" onClick={() => {
                    props.onHide(false)
                    setData(null)
                }}>
                    {intl.formatMessage({ id: 'GENERAL.VALIDATION.CANCEL' })}
                </Button>
                {printButton()}
            </Modal.Footer>
        </Modal>
    );
}


export { CustomModal }