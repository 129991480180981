/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {createBrowserHistory} from 'history'
import {Languages} from '../../../partials'
import {useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useTheme, setTheme} from '../../ThemeProvider/ThemeProvider'
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr'
import {api} from '../../../../constants'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import clsx from 'clsx'
import {getNotifications, testNotifications} from '../../../../app/modules/profile/redux/UserCRUD'
import axios from 'axios'
import {formatNotificationDate, notify} from '../../../../app/helpers/GeneralHelper'
import {Notification} from '../../../../app/modules/admin/components/notifications/models/Notification'
import Skeleton from 'react-loading-skeleton'
import {Button} from 'react-bootstrap-v5'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const NotificationsMenu: FC = () => {
  const history = createBrowserHistory()

  const accessToken = useSelector<RootState>(({auth}: any) => auth.token, shallowEqual) as string
  const user: UserModel = useSelector<RootState>(
    ({auth}: any) => auth.user,
    shallowEqual
  ) as UserModel
  const cancelSource = axios.CancelToken.source()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [totalUnread, setTotalUnread] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true) // status of the notifications list

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    getNotifications(0, 10, null, cancelSource.token)
      .then((response) => {
        setNotifications(response.data.notifications)
        // setTotalUnread(response.data.totalUnread || 0)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.message) {
          notify({
            title: intl.formatMessage({id: 'GENERAL.ERRORTITLE'}),
            text: intl.formatMessage(
              {id: `GENERAL.ERROR.${error.message}`},
              {params: error.params || ''}
            ),
            icon: 'error',
          })
        }
      })
  }

  useEffect(() => {
    if (!connection && accessToken) {
      const conn = new HubConnectionBuilder()
        .withUrl(api.notifications.hub, {
          accessTokenFactory: () => accessToken!,
        })
        .withAutomaticReconnect()
        .build()

      conn.on('ReceiveNotification', (notification: any) => {
        console.log('ReceiveNotification', notification)
        //setNotifications((notifications: Notification[]) => [...notifications, notification])
        getData()
        setTotalUnread((pre) => pre + 1)
      })

      conn
        .start()
        .then(() => {
          console.log('Connected!')
        })
        .catch((error: any) => console.error('Connection failed: ', error))

      setConnection(conn)
    }
    return () => {
      connection && connection.stop()
    }
  }, [accessToken])

  const sendTestNotification = async () => {
    // if (connection) {
    //   connection
    //     .invoke('SendPersonalNotification', user.id, {
    //       id: 1,
    //       contentAr:
    //         'تم تسجيل مخالفة مرورية جديدة للسيارة رقم اللوحة 12334 ورقم العقد 12334، بواسطة المستأجر Waseem Barmo في 31 Aug 2024 09:01',
    //       contentEn:
    //         'A new traffic ticket was captured for car with plate number 12334 and contract number 123142, by the renter Waseem Barmo at 31 Aug 2024 09:01',
    //       isRead: false,
    //       link: '/operations/trafficTickets?contract=10986635',
    //       organizationId: '123',
    //       userId: '132',
    //     })
    //     .then((response) => {
    //       console.log('SendPersonalNotification Sent Successfully', {response})
    //     })
    //     .catch((error: any) => console.error('SendPersonalNotification failed: ', error))
    // }

    testNotifications()
      .then((res) => {
        console.log('test success', res)
      })
      .catch((err) => {
        console.log('test error ', err)
      })
  }

  return (
    <div
      className={clsx('d-flex align-items-center  ', toolbarButtonMarginClass)}
      id='kt_header_notifications_menu_toggle'
    >
      <div
        className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
        onClick={() => setTotalUnread(0)}
      >
        <span className='  relative'>
          {!!totalUnread && (
            <span
              className={`grow animate`}
              style={{
                position: 'absolute',
                top: -5,
                right: 0,
                width: '17px',
                height: '17px',
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {totalUnread}
            </span>
          )}
          <KTSVG
            path={'/media/icons/duotune/general/gen007.svg'}
            className='svg-icon-1'
            svgClassName='mh-50px h-40px'
          />
        </span>
      </div>
      {/* {user.level === 0 && (
        <Button onClick={sendTestNotification} size='sm'>
          Test
        </Button>
      )} */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
            {intl.formatMessage({id: 'GENERAL.Notification'})}
            {!!totalUnread && (
              <span className='fs-8 opacity-75 ps-3'>{`${totalUnread} ${intl.formatMessage({
                id: 'GENERAL.TOTALUNREAD',
              })}`}</span>
            )}
          </h3>
        </div>

        <div>
          <div className='scroll-y mh-325px my-5 px-2'>
            {!loading &&
              !!notifications?.length &&
              notifications.map((notificaiton, index) => {
                const content =
                  intl.locale === 'ar' ? notificaiton.contentAr : notificaiton.contentEn
                return (
                  <div key={`alert_${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light-danger`)}>
                          <KTSVG
                            path={toAbsoluteUrl('/media/icons/duotune/maps/map001.svg')}
                            className={`svg-icon-2 svg-icon-danger`}
                          />
                        </span>
                      </div>

                      <div className='mb-0 me-2'>
                        {!!notificaiton.link && (
                          <div className='d-flex align-items-center justify-content-between'>
                            <a
                              href={notificaiton.link}
                              className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                            >
                              {intl.formatMessage({id: 'TRAFFICTICKETS.NEWTRAFFICTICKET'})}
                            </a>
                            <span className='badge badge-light fs-8'>
                              {formatNotificationDate(notificaiton.createDate, intl)}
                            </span>
                          </div>
                        )}
                        {!notificaiton.link && (
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='fs-6 text-gray-800  fw-bolder'>
                              {intl.formatMessage({id: 'TRAFFICTICKETS.NEWTRAFFICTICKET'})}
                            </p>
                            <span className='badge badge-light fs-8'>
                              {formatNotificationDate(notificaiton.createDate, intl)}
                            </span>
                          </div>
                        )}

                        <div className='text-gray-600 fs-7'>{content}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            {loading && (
              <Skeleton
                height={20}
                style={{margin: '7px 0'}}
                count={8}
                width='100%'
                containerClassName='w-100'
              />
            )}
            {!loading && !notifications?.length && (
              <p className='text-gray-800 fs-7'>
                {intl.formatMessage({id: 'NOTIFICATIONS.NONOTIFICATIONS'})}
              </p>
            )}
          </div>
          {/* <div className='py-3 text-center border-top'>
            <Link
              to='/user/notifications'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              {intl.formatMessage({id: 'MENU.VIEWALL'})}
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export {NotificationsMenu}
