import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap-v5";
import { useIntl } from "react-intl";




function DateModal(props: any) {
    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group controlId="dob">
                            <Form.Label>{props.inputTitle}</Form.Label>
                            <Form.Control type="date" name="returnDay" placeholder={props.inputTitle} onChange={(e: any) => props.handleSelectDate(e.target.value)} style={{ borderColor: props.error ? 'red' : '' }} />
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>{props.buttons[1]}</Button>
                <Button onClick={props.handleSubmit}>{props.buttons[0]}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function DatePickerModal({ open, handleSelectDate, onHide }: any) {
    const [modalShow, setModalShow] = useState(false);
    const [date, setDate] = useState(false);
    const [error, setError] = useState(false);
    const intl = useIntl()
    useEffect(() => {
        setModalShow(open)
    }, [open])
    const handleSubmit = () => {
        if (date) {
            setError(false)
            setModalShow(false)
            handleSelectDate(date)
        }
        else {
            setError(true)
            return;
        }
    }
    return (
        <DateModal
            show={modalShow}
            onHide={() => { setModalShow(false); onHide() }}
            title={intl.formatMessage({ id: 'MENU.RETURNEDCARS' }, { period: intl.formatMessage({ id: 'GENERAL.PERDATE' }) })}
            inputTitle={intl.formatMessage({ id: 'GENERAL.CHOOSEDATE' })}
            buttons={[intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' }), intl.formatMessage({ id: 'GENERAL.VALIDATION.CANCEL' })]}
            handleSelectDate={(date: any) => { setDate(date); setError(false) }}
            handleSubmit={handleSubmit}
            error={error}
        />
    );
}

export { DatePickerModal }