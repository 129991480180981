/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { constants } from '../../../constants'
import { useLayout } from '../core'
import { useTheme } from '../ThemeProvider/ThemeProvider'

const Footer: FC = () => {
  const intl = useIntl()
  const { config } = useLayout()
  const theme = useTheme()
  const isCustomTheme = theme === 'custom'
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column no-print' id='kt_footer' style={{ background: isCustomTheme ? (config.footerBackground ?? '') : '' }}>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between no-print`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1 no-print'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href={constants.appLink} className='text-gray-800 text-hover-primary' style={{ color: isCustomTheme ? (config.footerTextColor ?? '') : '' }}>
            {constants.appTitle}
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 no-print'>
          <li className='menu-item no-print'>
            <a href={constants.appAboutLink} className='menu-link ps-0 pe-2 no-print' style={{ color: isCustomTheme ? (config.footerTextColor ?? '') : '' }}>
              {intl.formatMessage({ id: 'APP.ABOUT' }, { name: constants.appTitle })}
            </a>
          </li>
          <li className='menu-item no-print'>
            <a href={constants.appContactLink} className='menu-link pe-0 pe-2 no-print' style={{ color: isCustomTheme ? (config.footerTextColor ?? '') : '' }}>
              {intl.formatMessage({ id: 'APP.CONTACTUS' })}
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
