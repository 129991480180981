import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'

import * as cars from '../../app/modules/Operations/Cars/redux/CarRedux'
import * as contract from '../../app/modules/Operations/Contracts/redux/ContractRedux'
import * as app from '../../app/modules/home/redux/AppRedux'
export const rootReducer = combineReducers({
  auth: auth.reducer,
  contract: contract.reducer,
  app: app.reducer
})

export type CarState = ReturnType<typeof cars.reducer>
export type ContractState = ReturnType<typeof contract.reducer>
export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all(
    [
      auth.saga()
    ]
  )
}
