/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {createBrowserHistory} from 'history'
import {Languages} from '../../../partials'

import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toApiPath} from '../../../../app/modules/admin/redux/General'
import {toAbsoluteUrl} from '../../../helpers'
import {useTheme, setTheme} from '../../ThemeProvider/ThemeProvider'

const HeaderUserMenu: FC<any> = ({user}) => {
  const history = createBrowserHistory()
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
    setTimeout(() => {
      history.push('/auth/login')
    }, 500)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={toApiPath(`/appData/${user?.imageUrl}?v=` + new Date())}
              onError={({currentTarget}) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
              }}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.name} {user?.lastName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        {/* @ts-ignore */}
        <Link to={'/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'USER.PROFILE'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        {/* @ts-ignore */}
        <Link to={'/settings'} className='menu-link px-5'>
          <span className='menu-text'>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
        </Link>
      </div>
      <div className='separator my-2'></div>
      <Languages />
      <div className='menu-item px-3'>
        {theme === 'custom' && (
          <p className='menu-link px-5' onClick={() => setTheme('light')}>
            {intl.formatMessage({id: 'LAYOUT.THEME.RESTOREDEFAULTS'})}
          </p>
        )}
        {theme !== 'custom' && (
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                name='isApproved'
                checked={theme === 'dark'}
                value={theme || ''}
                onChange={(e: any) => {
                  setTheme(theme === 'dark' ? 'light' : 'dark')
                }}
              />
              <label className='form-check-label' htmlFor='flexSwitchDefault'>
                {theme === 'dark'
                  ? intl.formatMessage({id: 'GENERAL.THEME.DARK'})
                  : intl.formatMessage({id: 'GENERAL.THEME.LIGHT'})}
              </label>
            </div>
          </div>
        )}
      </div>

      <div className='menu-item px-5'>
        <a
          onClick={logout}
          className='menu-link px-5'
          title={intl.formatMessage({id: 'AUTH.LOGOUT'})}
        >
          {intl.formatMessage({id: 'AUTH.LOGOUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
