import clsx from 'clsx'
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {useLayout} from '../../core'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {toApiPath} from '../../../../app/modules/admin/redux/General'
import {HeaderUserMenu} from './UserMenu'
import {NotificationsMenu} from './NotificationsMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

interface Notification {
  title: string
  description: string
  time: string
  icon: string
  state: string
}

const Topbar: FC = () => {
  const {config} = useLayout()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div className='d-flex justify-content-start flex-row-reverse align-items-center flex-shrink-0 no-print'>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            alt='Logo'
            src={toApiPath(`/appData/${user?.imageUrl}?v=` + new Date())}
            onError={({currentTarget}) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
            }}
          />
        </div>

        <HeaderUserMenu user={user} />

        {/* end::Toggle */}
      </div>

      <NotificationsMenu />
    </div>
  )
}

export {Topbar}
