/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl, toImageUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {ReactReduxContext} from 'react-redux'
import {api} from '../../../../constants'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {useDispatch} from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {createBrowserHistory} from 'history'
import {useTheme} from '../../ThemeProvider/ThemeProvider'
const history = createBrowserHistory()
const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const theme = useTheme()
  const isCustomTheme = theme === 'custom'
  const {aside} = config
  const dispatch = useDispatch()

  // @ts-ignore */
  const {store} = useContext(ReactReduxContext)
  const [user, setUser] = useState<UserModel>(store.getState().auth.user)
  const intl = useIntl()
  useEffect(() => {
    setUser(store.getState().auth.user)
    return () => setUser(store.getState().auth.user)
  }, [])

  const logout = () => {
    dispatch(auth.actions.logout())
    setTimeout(() => {
      history.push('/')
    }, 500)
  }
  return (
    <div
      id='kt_aside'
      className={clsx('aside no-print ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      style={{background: isCustomTheme ? config.asideBackground ?? '' : ''}}
    >
      {/* begin::Brand */}
      <div
        className='aside-logo flex-column-auto'
        id='kt_aside_logo'
        style={{background: isCustomTheme ? config.asideHeaderBackground ?? '' : ''}}
      >
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          // @ts-ignore
          <Link to='/dashboard'>
            {/* <img
              alt='Logo'
              className='h-25px logo asideLogo no-print'
              src={toImageUrl(`/organizations/${user?.organizationId || ''}/${user?.organizationId || ''}.png`)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = toImageUrl(`/defaultLogo.png`)
              }}
            /> */}
            <h1 className='h-25px logo asideLogo no-print text-white'>{user?.companyName}</h1>
          </Link>
        )}
        {aside.theme === 'light' && (
          // @ts-ignore
          <Link to='/dashboard'>
            {/* <img
              alt='Logo'
              className='h-25px logo asideLogo no-print'
              src={toImageUrl(`/organizations/${user?.organizationId || ''}/${user?.organizationId || ''}.png`)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = toImageUrl(`/defaultLogo.png`)
              }}
            /> */}
            <h1 className='h-25px logo asideLogo no-print text-white'>{user?.companyName}</h1>
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle no-print'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid no-print'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} user={user} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5 no-print' id='kt_aside_footer'>
        <button
          className='btn btn-custom btn-danger w-100 no-print'
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          onClick={() => logout()}
          title={intl.formatMessage({id: 'AUTH.LOGOUT'})}
        >
          <span className='btn-label no-print'>{intl.formatMessage({id: 'AUTH.LOGOUT'})}</span>
          <span className='svg-icon btn-icon svg-icon-2 no-print'>
            <KTSVG path='/media/icons/duotune/arrows/arr036.svg' />
          </span>
        </button>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
