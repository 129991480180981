import {api} from '../../../../constants'
import axios from 'axios'

export const FILES_URL = `${api.filesRoot}`
export const DOWNLOADFILE_URL = `${api.general.downloadFile}`
export const DELETEFILE_URL = `${api.general.deleteFile}`

export function toApiPath(path: String) {
  return FILES_URL + path
}

export function doDeleteAttachment(path: string) {
  const dataForm = new FormData()
  dataForm.append('path', path)
  return axios.post(DELETEFILE_URL, dataForm)
}

export function getFile(path: string, fileName: string) {
  return axios({
    url: DOWNLOADFILE_URL + ('?path=' + path),
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}

export function getErrors(obj: any) {
  try {
    let errors = obj?.response?.data?.errors
      ? Object.keys(obj.response.data.errors)
          .map((key) => obj.response.data.errors[key][0])
          .join(',')
      : ''
    errors =
      errors ||
      obj.response.data ||
      obj.response.data.errors?.$?.join(',') ||
      obj.response.data.title
    if (typeof errors !== 'string') {
      errors = obj.response.data.title
    }

    return errors || ''
  } catch (error) {
    return ''
  }
}
