import React, { useEffect } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import { useIntl } from 'react-intl'
import { useTheme } from './ThemeProvider/ThemeProvider'
const MasterLayout: React.FC = ({ children }) => {

  const location = useLocation()
  const intl = useIntl()
  const { config } = useLayout()
  const theme = useTheme()
  const isCustomTheme = theme === 'custom'


  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])


  const themeFont: React.CSSProperties = {
    fontSize: isCustomTheme ? (config.fontsize ?? '') : '',
    fontWeight: isCustomTheme ? (config.fontWeight ?? '') : ''
  }
  const pageBg: React.CSSProperties = {
    background: isCustomTheme ? (config.pageBackground ?? '') : ''

  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid' style={themeFont}>
        <AsideDefault />
        <div style={pageBg} className='wrapper d-flex flex-column flex-row-fluid ' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid '>
            {/*<Toolbar />*/}
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>

          </div>
          <Footer />

        </div>
        <div id='loading-spinner' className="wrapper overlay-layer rounded bg-dark bg-opacity-5 position-absolute d-flex flex-column flex-row-fluid w-100 h-100 d-flex justify-content-center align-items-center top-0 d-none">
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="visually-hidden">{intl.formatMessage({ id: 'GENERAL.LOADING' })}</span>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <ExploreMain />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}

      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
